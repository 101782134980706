import React from "react";

import classes from "./PackageComponent.module.css";
import { AiOutlineCheck } from "react-icons/ai";
import { Button } from "../Button/Button";

const PackageComponent = ({
  data,
  onClick,
  btnVisibility = true,
  className,
}) => {
  const pricesList = data?.prices?.map((e) =>
    e?.currency === "usd" ? `$${e?.amount}` : `£${e?.amount}`
  );

  return (
    <div
      className={`${classes?.packageMain} ${className && className}`}
      onClick={onClick}
    >
      <div className={classes?.distributionContainer}>
        {/* Class Per Week */}
        <span>
          {data?.numberOfClasses}{" "}
          {data?.numberOfClasses > 1 ? "classes" : "class"} per week
        </span>
      </div>
      <h3>{pricesList?.join("/")}</h3>
      <p className={classes?.perMonth}>Per Course</p>
      <div className={classes?.descriptionContainer}>
        {data?.description?.map((d, i) => (
          <div className={classes?.descriptionItem} key={i}>
            <AiOutlineCheck fill="green" size={35} />
            <span className="t-t-c">{d}</span>
          </div>
        ))}
      </div>
      {btnVisibility && (
        <div className={classes?.buttonContainer}>
          <Button label={"Book Free Trial"} />
        </div>
      )}
    </div>
  );
};

export default PackageComponent;
