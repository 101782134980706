import React from "react";
// import classes from "./AnnouncementViewModal.module.css";
import { Col, Container, Row } from "react-bootstrap";

import ModalSkeleton from "../ModalSkeleton";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import moment from "moment";

const AnnouncementViewModal = ({
  show,
  setShow,
  //   handleSubmit,
  //   isLoading = false,
  selectedData,
}) => {
  console.log(selectedData, "selectedData?.role v selectedData?.role");
  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="750px"
        borderRadius="20px"
        header={`View Announcement`}
        showCloseIcon
      >
        <Container>
          <Row>
          <Col md={6}>
              <p>
                <b>Title: </b>
                {
                  <ShowMoreShowLessText
                    text={selectedData?.title}
                    visibility={30}
                  />
                }
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b>Role: </b>
                {selectedData?.role.map((item) => item).join(", ")}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b>Start Date: </b>
                {moment(selectedData?.startDate).format("DD-MM-YYYY")}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b>End Date: </b>
                {moment(selectedData?.endDate).format("DD-MM-YYYY")}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b>Created At: </b>
                {moment(selectedData?.createdAt).format("DD-MM-YYYY")}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b>Status: </b>
                {selectedData?.status == "active" ? "Active" : "Inactive"}
              </p>
            </Col>
            
            <Col md={6}>
              <p>
                <b>Assigned Users: </b>
                {selectedData?.users?.length}
              </p>
            </Col>
            <Col md={12}>
              <p>
                <b>Description: </b>
                <ShowMoreShowLessText
                  text={selectedData?.description}
                  visibility={200}
                />
              </p>
            </Col>
          </Row>
        </Container>
      </ModalSkeleton>
    </div>
  );
};

export default AnnouncementViewModal;
