/* eslint-disable */
import React, { useState, useEffect } from "react";

import classes from "./AddOrEditTeacherPage.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import { Input } from "../../Component/Input/Input";
import PhoneInputCompo from "../../Component/PhoneInputCompo";
import { TimezoneDropDown } from "../../Component/TimezoneDropDown/TimezoneDropDown";
import { Radio } from "../../Component/Radio/Radio";
import { TextArea } from "../../Component/TextArea";
import { Button } from "../../Component/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Maps from "../../Component/MapAndPlaces";
import { toast } from "react-toastify";
import { Country, City } from "country-state-city";

import {
  BaseURL,
  apiHeader,
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer,
  validateEmail,
} from "../../config/apiUrl";
import { useSelector } from "react-redux";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { DropDown } from "../../Component/DropDown/DropDown";

const AddOrEditTeacherPage = () => {
  const editableTeacher = useLocation()?.state?.item;
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const [photo, setPhoto] = useState(editableTeacher?.photo || "");
  const [email, setEmail] = useState(editableTeacher?.email || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState(editableTeacher?.firstName || "");
  const [lastName, setLastName] = useState(editableTeacher?.lastName || "");
  const [phone, setPhone] = useState(editableTeacher?.phone || "");
  const [altPhone, setAltPhone] = useState(editableTeacher?.altPhone || "");
  const [coordinates, setCoordinates] = useState(
    editableTeacher?.location?.coordinates
      ? {
          lat: editableTeacher?.location?.coordinates[1],
          lng: editableTeacher?.location?.coordinates[0],
        }
      : null
  );
  const [address, setAddress] = useState(editableTeacher?.address || "");
  const [timeZone, setTimeZone] = useState(
    editableTeacher ? { value: editableTeacher?.timeZone } : ""
  );
  const [nationality, setNationality] = useState(
    editableTeacher?.nationality || ""
  );
  const [gender, setGender] = useState(editableTeacher?.gender || "");
  const [age, setAge] = useState(editableTeacher?.age || "");
  const [description, setDescription] = useState(
    editableTeacher?.description || ""
  );
  const [maritalStatus, setMaritalStatus] = useState(
    editableTeacher?.maritalStatus ? "married" : "single"
  );
  const [country, setCountry] = useState(
    editableTeacher ? { name: editableTeacher?.country } : ""
  );
  const [city, setCity] = useState(
    editableTeacher ? { name: editableTeacher?.city } : ""
  );
  const [CNIC, setCNIC] = useState(editableTeacher?.CNIC || "");
  const [amountPerHour, setAmountPerHour] = useState(
    editableTeacher?.amountPerHour || ""
  );
  const [courses, setCourses] = useState(editableTeacher?.courses || "");
  const [qualification, setQualification] = useState(
    editableTeacher?.qualification || ""
  );
  const [experience, setExperience] = useState(
    editableTeacher?.experience || ""
  );

  // loading states + other
  const [isLoading, setIsLoading] = useState(false);
  const [coursesData, setCoursesData] = useState([]);

  // validate params locally
  const handleValidateLocally = () => {
    const params = {
      photo,
      email,
      ...(!editableTeacher && { password, confirmPassword }),
      firstName,
      lastName,
      phone,
      altPhone,
      timeZone: timeZone?.value,
      address,
      nationality,
      CNIC,
      country: country?.name,
      age,
      amountPerHour,
      qualification,
      experience,

      gender,
      description,
      lat: coordinates?.lat,
      lng: coordinates?.lng,
      role: "teacher",
    };

    // validate
    for (let key in params)
      if (!params[key] || params[key] === "") {
        toast.warn(
          `${capitalizeFirstLetter(
            key?.replace(formRegEx, formRegExReplacer)
          )} is required.`
        );
        return [false, null];
      }

    if (!validateEmail(email)) {
      toast.warn("Invalid Email Address");
      return [false, null];
    }

    if (!editableTeacher) {
      if (password?.length < 8) {
        toast.warn("Password must be 8 characters long.");
        return [false, null];
      }
      if (password !== confirmPassword) {
        toast.warn("Password and Confirm Password must be same.");
        return [false, null];
      }
    }
    if (isNaN(age)) {
      toast.warn("Age must be a number.");
      return [false, null];
    }
    if (age < 19) {
      toast.warn("Age must be greater than 18.");
      return [false, null];
    }
    if (isNaN(amountPerHour)) {
      toast.warn("Amount per hour must be a number.");
      return [false, null];
    }

    if (courses.length === 0) {
      toast.warn("Please select at least one course.");
      return [false, null];
    }

    params.maritalStatus = maritalStatus === "married" ? true : false;
    params.city = city?.name || "";

    return [true, params];
  };

  // handle Submit
  const handleSubmit = async () => {
    const [isValid, params] = await handleValidateLocally();

    if (!isValid) return;

    const formData = new FormData();
    for (let key in params) formData.append(key, params[key]);
    for (let key in courses) formData.append("courses[]", courses[key]?._id);

    let url = BaseURL("admin/create-subadmin-teacher");
    if (editableTeacher)
      url = BaseURL(`admin/update-subadmin-teacher/${editableTeacher?._id}`);

    const fn = editableTeacher ? Patch : Post;
    setIsLoading(true);
    const response = await fn(url, formData, apiHeader(accessToken, true));
    setIsLoading(false);

    if (response) {
      toast.success(
        `Teacher ${editableTeacher ? "added" : "edited"} Successfully`
      );
      navigate(-1);
    }
    // navigate("/package");
  };

  // get all courses
  const getAllCourses = async () => {
    const url = BaseURL(`course/all`);
    setIsLoading(true);
    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setCoursesData(response?.data?.data);
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  return (
    <div>
      <SideBarSkeleton>
        <div className={classes?.topContainer}>
          <div className={classes?.topStrip}>
            <h2>{editableTeacher ? "Edit" : "Add New"} Teacher</h2>
          </div>
          <Container className={classes.main}>
            <div className={classes?.profileImageContainer}>
              <ProfileWithEditButton
                updateImage={photo}
                setUpdateImage={setPhoto}
                isEdit={true}
                onClick={() => {}}
              />
            </div>
            <Row>
              <Col md={12} className={`mt-3`}>
                <Input
                  setter={setEmail}
                  value={email}
                  placeholder={"Email Address"}
                  label={"Email Address"}
                  type={"text"}
                  disabled={editableTeacher}
                />
              </Col>
              {!editableTeacher && (
                <>
                  <Col md={6} className={`mt-3`}>
                    <Input
                      setter={setPassword}
                      value={password}
                      placeholder={"Password"}
                      label={"Password"}
                      type={"password"}
                    />
                  </Col>
                  <Col md={6} className={`mt-3`}>
                    <Input
                      setter={setConfirmPassword}
                      value={confirmPassword}
                      placeholder={"Confirm Password"}
                      label={"Confirm Your Password"}
                      type={"password"}
                    />
                  </Col>
                </>
              )}
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setFirstName}
                  value={firstName}
                  placeholder={"First Name Here"}
                  label={"First Name"}
                  type={"text"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setLastName}
                  value={lastName}
                  placeholder={"Last Name Here"}
                  label={"Last Name"}
                  type={"text"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <PhoneInputCompo
                  label={"Phone number"}
                  setter={setPhone}
                  value={phone}
                  disabled={editableTeacher}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <PhoneInputCompo
                  label={"Alt phone number"}
                  setter={setAltPhone}
                  value={altPhone}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <TimezoneDropDown
                  value={timeZone}
                  setter={setTimeZone}
                  isSearchable={true}
                  label={"Timezone"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Maps
                  label={"Address"}
                  type="location"
                  setCoordinates={setCoordinates}
                  setAddress={setAddress}
                  address={address}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setNationality}
                  value={nationality}
                  placeholder={"Nationality Here"}
                  label={"Nationality"}
                  type={"text"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setCNIC}
                  value={CNIC}
                  placeholder={"NIC Here"}
                  label={"NIC Number"}
                  type={"number"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <DropDown
                  isSearchable={true}
                  label={"Select Country"}
                  options={Country.getAllCountries()}
                  optionLabel={"name"}
                  optionValue={"name"}
                  setter={(e) => {
                    setCountry(e);
                  }}
                  value={country}
                  customStyle={{ minWidth: "180px" }}
                  placeholder={"Select Country"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <DropDown
                  isSearchable={true}
                  label={"Select City"}
                  options={City.getCitiesOfCountry(country?.isoCode)}
                  optionLabel={"name"}
                  optionValue={"name"}
                  setter={(e) => {
                    setCity(e);
                  }}
                  value={city}
                  customStyle={{ minWidth: "180px" }}
                  placeholder={"Select City"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setAge}
                  value={age}
                  placeholder={"Age Here"}
                  label={"Age"}
                  type={"number"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setAmountPerHour}
                  value={amountPerHour}
                  placeholder={"Amount Per Hour Here"}
                  label={"Amount Per Hour"}
                  type={"number"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setQualification}
                  value={qualification}
                  placeholder={"Qualification Here"}
                  label={"Qualification"}
                  type={"text"}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setExperience}
                  value={experience}
                  placeholder={"Experience Here"}
                  label={"Experience"}
                  type={"text"}
                />
              </Col>
              <Col md={12} className={`mt-3`}>
                <DropDown
                  isSearchable={true}
                  label={"Select Courses"}
                  options={coursesData}
                  optionLabel={"title"}
                  optionValue={"_id"}
                  setter={setCourses}
                  value={courses}
                  customStyle={{ minWidth: "180px" }}
                  placeholder={"Select Courses"}
                  isMulti={true}
                />
              </Col>
              <Col md={6} className={`mt-3`}>
                <label className={classes?.customLabel}>Select Gender</label>
                <Radio label="male" value={gender} setValue={setGender} />
                <Radio label="female" value={gender} setValue={setGender} />
              </Col>
              <Col md={6} className={`mt-3`}>
                <label className={classes?.customLabel}>Marital Status</label>
                <Radio
                  label="married"
                  value={maritalStatus}
                  setValue={setMaritalStatus}
                />
                <Radio
                  label="single"
                  value={maritalStatus}
                  setValue={setMaritalStatus}
                />
              </Col>

              <Col md={12} className={`mt-3`}>
                <TextArea
                  value={description}
                  setter={setDescription}
                  placeholder={"Enter description"}
                  label={"Description"}
                />
              </Col>
              <div className={classes?.buttonContainer}>
                <Button label={"Submit"} onClick={handleSubmit} />
              </div>
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </div>
  );
};

export default AddOrEditTeacherPage;
