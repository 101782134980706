import React from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./SalaryDetailModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { useState } from "react";
import { BaseURL, apiHeader } from "../../config/apiUrl";
import { Patch } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import AreYouSureModal from "../AreYouSureModal"
const SalaryDetailModal = ({ show, setShow, data, setData, selected }) => {
  const { access_token } = useSelector((state) => state?.authReducer);
  const [showPayNow, setShowPayNow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeSalaryStatus = async () => {
    const url = BaseURL(`salary/paid/${selected?._id}`);
    setIsLoading(true);
    const response = await Patch(url, {}, apiHeader(access_token));
    if (response) {
      const temp = [...data];
      temp.splice(
        temp.findIndex((i) => i?._id == response?.data?.data?._id),
        1,
        response?.data?.data
      );
      setData(temp);
      setShow(false);
      setShowPayNow(false);
      toast.success("Salary Successfully Paid");
    }
    setIsLoading(false);
  };
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      width={"550px"}
      borderRadius={"10px"}
      borderLine={false}
      header={showPayNow ? "Are You Sure!" : "Salary Detail"}
      showCloseIcon
      onClickShowCloseICon={() => {
        setShowPayNow(false);
      }}
      // headerClass={classes.header}
    >
      <Row>
        {showPayNow ? (
          <div className={classes.content}>
            <div className={classes.mainDiv}>
              <p className={[classes.message].join(" ")}>Do you Want to Pay?</p>
            </div>
            <div className={classes.btnsBox}>
              <Button
                className={classes.yesBtn}
                onClick={changeSalaryStatus}
                disabled={isLoading}
              >
                {isLoading ? "Wait..." : "Yes"}
              </Button>
              <Button
                className={classes.noBtn}
                onClick={async () => {
                  setShowPayNow(false);
                }}
                disabled={isLoading}
              >
                No
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Col md={6} className={classes.detail}>
              <p>Teacher Name:</p>
              <p>
                {[
                  selected?.teacher?.firstName,
                  selected?.teacher?.lastName,
                ].join(" ")}
              </p>
            </Col>
            <Col md={6} className={classes.detail}>
              <p>Salary:</p>
              <p>${selected?.amount}</p>
            </Col>
            <Col md={6} className={classes.detail}>
              <p>Hours:</p>
              <p>{selected?.hours} hrs</p>
            </Col>

            <Col md={6} className={classes.detail}>
              <p>No. Of Classes:</p>
              <p>{selected?.classes?.length} Classes</p>
            </Col>
            <Col md={6} className={classes.detail}>
              <p>Status</p>
              <p>{selected?.status === "notPaid" ? "Unpaid" : "Paid"}</p>
            </Col>
            {selected?.status === "notPaid" && (
              <Col md={12} className={classes.btnWrap}>
                <Button
                  className={classes.payBtn}
                  label={"Pay Now"}
                  onClick={() => {
                    setShowPayNow(true);
                  }}
                />
              </Col>
            )}
          </>
        )}
      </Row>
      {/* <AreYouSureModal subTitle="Are you want to pay?" show={showPayNow} setShow={setShowPayNow}/> */}
    </ModalSkeleton>
  );
};

export default SalaryDetailModal;
