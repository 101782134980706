/* eslint-disable */

import {
  FaGgCircle,
  FaHome,
  FaMoneyBillAlt,
  FaUserCircle,
} from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { HiUsers } from "react-icons/hi";
import { RiDashboardFill } from "react-icons/ri";
import { IoIosPeople, IoMdNotifications } from "react-icons/io";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoGitPullRequest, GoVerified } from "react-icons/go";
import { IoPersonCircle, IoTodaySharp } from "react-icons/io5";
import { AiFillSchedule } from "react-icons/ai";
import { MdAnnouncement } from "react-icons/md";
import { SiMoneygram } from "react-icons/si";

const CreateSideBarMenu = (userRole) => {
  const SideBarMenu = [
    {
      name: "Dashboard",
      icon: <RiDashboardFill size={22} />,
      path: "/dashboard",
    },
    {
      name: "All Families",
      icon: <IoIosPeople size={22} />,
      path: "/family/all",
    },
    userRole == "admin" && {
      name: "Employee",
      icon: <BsFillPeopleFill size={22} />,
      subMenu: [
        {
          path: "/teacher/all",
          label: "Teacher",
          icon: <FiPackage size={20} />,
        },
        {
          path: "/sub-admin/all",
          label: "Sub admin",
          icon: <FaUserCircle size={20} />,
        },
      ],
    },
    {
      name: "Subscriptions",
      icon: <FaMoneyBillAlt size={22} />,
      path: "/subscriptions/all",
    },
    {
      name: "Holidays",
      icon: <FaMoneyBillAlt size={22} />,
      path: "/holidays",
    },
    {
      name: "Class Requests",
      icon: <GoGitPullRequest size={22} />,
      path: "/class/request",
    },
    {
      name: "Today's Classes",
      icon: <IoTodaySharp size={22} />,
      path: "/class/today",
    },
    {
      name: "Classes",
      icon: <AiFillSchedule size={22} />,
      path: "/class/all",
    },
    {
      name: "All Courses",
      icon: <GoVerified size={22} />,
      path: "/course/all",
    },
    {
      name: "Packages",
      icon: <FaMoneyBillAlt size={22} />,
      path: "/packages",
    },
    {
      name: "Complain",
      icon: <FaGgCircle size={22} />,
      subMenu: [
        // {
        //   path: "/complain/teacher",
        //   label: "Regarding Teacher",
        //   icon: <FiPackage size={20} />,
        // },
        // {
        //   path: "/complain/other",
        //   label: "Other Issues",
        //   icon: <FaUserCircle size={20} />,
        // },
        {
          path: "/complain/all",
          label: "Complain Records",
          icon: <HiUsers size={20} />,
        },
      ],
    },
    {
      name: "Task & Announcement",
      icon: <MdAnnouncement size={22} />,
      subMenu: [
        {
          path: "/task/all",
          label: "Tasks",
          icon: <FiPackage size={20} />,
        },
        {
          path: "/announcement/all",
          label: "Announcements",
          icon: <FaUserCircle size={20} />,
        },
      ],
    },
    userRole == "admin" && {
      name: "Salaries",
      icon: <FaMoneyBillAlt size={22} />,
      path: "/salaries",
    },
    {
      name: "Notifications",
      icon: <IoMdNotifications size={22} />,
      path: "/notifications",
    },
    {
      name: "Transactions",
      icon: <SiMoneygram size={22} />,
      path: "/transactions",
    },
    {
      name: "Profile",
      icon: <IoPersonCircle size={22} />,
      path: "/my-profile",
    },
  ].filter(Boolean);

  return SideBarMenu;
};

const UploadImageValidtor = (file) => {
  const validImageTypes = [
    // "image/gif",
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/webp",
  ];
  if (!validImageTypes.includes(file["type"])) {
    return false;
  }
  return true;
};
const UploadVideoValidtor = (file) => {
  const acceptedTypes = [
    "video/mp4",
    ".mkv",
    ".avi", // .video file extention list
  ];
  const fileType = file.type;
  if (!acceptedTypes.includes(file["type"])) {
    return false;
  }
  return true;
};

export { CreateSideBarMenu, UploadImageValidtor, UploadVideoValidtor };
