import React, { useEffect, useState } from "react";
import classes from "./HeaderNotification.module.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BaseURL } from "../../config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import NoData from "../NoData/NoData";
import { MdNotifications } from "react-icons/md";

const Notifications = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      className={[classes.HeaderNotificationInnerDiv]}
      onClick={() => {
        item?.flag == "project" &&
          navigate(`/project-detail/${item?.project?._id}`);
        item?.flag == "chat" &&
          navigate(`/chat`, {
            state: {
              currentRoom: item?.project?.room,
            },
          });
      }}
    >
      <div className={[classes.ImgDiv]}>
        <MdNotifications size={20} />
      </div>
      <div className={[classes.contentDiv]}>
        <div className={[classes.titleDiv]}>
          <h6>{item?.title}</h6>
          <p>{moment(item?.createdAt).format("Do MMM YYYY hh:mm a")}</p>
        </div>
        <p>{item?.message}</p>
      </div>
    </div>
  );
};

const HeaderNotification = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    const url = BaseURL("users/notifications?page=1&limit=5");
    setLoading(true);
    let response = await Get(url, accessToken);
    setLoading(false);

    if (response !== undefined) {
      setNotifications(response?.data?.data);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);
  return (
    <div className={[classes.HeaderNotification]}>
      <div className={[classes.notificationHeader]}>
        <h6>Notifications</h6>
      </div>
      <div className={classes.notificationSkeleton}>
        {loading ? (
          Array(4)
            .fill(0)
            ?.map((item, key) => (
              <Skeleton
                item={item}
                key={key}
                height={"80px"}
                width={"100%"}
                variant={"rectangular"}
              />
            ))
        ) : notifications?.length == 0 ? (
          <NoData text={"No Notifications Found"} className={classes.noData} />
        ) : (
          notifications
            ?.slice(Math.max(notifications?.length - 5, 0))
            ?.map((item, i) => {
              return (
                <div className={[classes.mb16]} key={i}>
                  <Notifications item={item} />
                </div>
              );
            })
        )}
        <p
          className={["p18", classes.viewAll].join(" ")}
          onClick={() => navigate("/notifications")}
        >
          See all
        </p>
      </div>
    </div>
  );
};
export default HeaderNotification;
