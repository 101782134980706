import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NoData from "../../Component/NoData/NoData";
import { Button } from "../../Component/Button/Button";
import TableSkeleton from "../../Component/TableSkeleton";
import classes from "./SubscriptionPage.module.css";
import { useNavigate } from "react-router-dom";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import PaginationComponent from "../../Component/PaginationComponent";

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const getData = async (pg=page) => {
    setLoading(true);
    const response = await Get(BaseURL(`admin/get-subscriptions?page=${pg}&limit=${recordsLimit}`), accessToken);
    setLoading(false);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <SideBarSkeleton heading={"List of Subscriptions"}>
        <Container className={classes.main}>
          <Row className={classes.row}>
            <Col md={12} className={classes.tableMain}>
              <div className="table100 ver1 m-b-110">
                <div className="table100-head">
                  <table>
                    <thead>
                      <tr className="row100 head">
                        <th
                          className="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>

                        <th
                          className="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Student Name
                        </th>
                        <th
                          className="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Teacher Name
                        </th>
                        <th
                          className="cell100 column4"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          No of Classes
                        </th>

                        <th
                          className="cell100 column5"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Package Type
                        </th>
                        <th
                          className="cell100 column5"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Course
                        </th>
                        <th
                          className="cell100 column5"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div className="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr className="row100 body" key={index}>
                              <td
                                className="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  {item?.student?.firstName}{" "}
                                  {item?.student?.lastName}
                                </div>
                              </td>
                              <td
                                className="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                {item?.teacher ? item?.teacher?.firstName+" "+
                                item?.teacher?.lastName: "-"}
                              </td>

                              <td
                                className="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {item?.classes?.length}
                                </div>
                              </td>
                              <td
                                className="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {item?.package?.packageType + " " + "mins"}
                                </div>
                              </td>

                              <td
                                className="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {item?.course?.title}
                                </div>
                              </td>

                              <td
                                className="cell100 column5"
                                style={{ width: "15%", textAlign: "start" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={"Details"}
                                    onClick={() => {
                                      navigate({
                                        pathname: `/subscriptions/${item?._id}`,
                                      });
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Subscriptions Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>
            <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && totalResults>recordsLimit && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e)=>{
                      setPage(e)
                      getData(e)
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
          </Row>
        </Container>
      </SideBarSkeleton>
    </div>
  );
};

export default SubscriptionPage;
