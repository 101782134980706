import React, { useEffect, useState } from "react";
import classes from "./ListOfChildrenOfParent.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../../config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import StudentCard from "../../Component/StudentCard/StudentCard";
import NoData from "../../Component/NoData/NoData";

const ListOfChildrenOfParent = () => {
  const navigate = useNavigate();
  const slug = useParams().slug;
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);

  // GET family members
  const getFamilyMembers = async () => {
    const url = BaseURL(`admin/get-parent-childrens/${slug}`);

    setIsLoading(true);
    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setFamilyMembers(response?.data?.data);
    }
  };

  useEffect(() => {
    getFamilyMembers();
  }, []);

  return (
    <div>
      <SideBarSkeleton>
        <div className={classes?.topContainer}>
          <div className={classes?.topStrip}>
            <h1>Family Members</h1>
          </div>
          <Container className={classes.main}>
            <Row className={classes?.studentMapperContainer}>
              {familyMembers?.map((student, index) => (
                <Col key={index} className={"mt-4"} lg={4} md={6} sm={12}>
                  <StudentCard
                    data={student}
                    onClick={() => navigate(`/user/${student?.slug}`)}
                  />
                </Col>
              ))}
            </Row>

            {!isLoading && familyMembers?.length === 0 && (
              <NoData text="No student found." />
            )}
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </div>
  );
};

export default ListOfChildrenOfParent;
