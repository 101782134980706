import React, { useState } from "react";
import styles from "./Packages.module.css";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import PricingCard from "../../Component/PricingCard";
import { useSelector } from "react-redux";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import { useEffect } from "react";
import { Get } from "../../Axios/AxiosFunctions";
import { Loader } from "../../Component/Loader";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import EditPackageModal from "../../modals/EditPackageModal";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
const Packages = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [isLoading, setIsLoading] = useState(false);

  const [packages, setPackages] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);

  const getPackages = async (pg=page) => {
    setIsLoading(true);
    const response = await Get(BaseURL(`package/all?page=${pg}&limit=${recordsLimit}`), accessToken);

    setIsLoading(false);
    if (response !== undefined) {
      setPackages(response?.data?.data);
      setTotalData(response?.data?.totalCount)
    }
  };
  useEffect(() => {
    getPackages();
  }, []);

  return (
    <SideBarSkeleton heading={"Packages"}>
      <Container className={styles.main}>
        <div className={styles.content__wrapper}>
          <div className={styles.alignRight}>
            {/* <h5>Packages</h5> */}
            <Button
              onClick={() => {
                setSelected(null);
                setShowModal(true);
              }}
              label="+ Create Package"
            />
          </div>
          {isLoading ? (
            <Loader className={styles.loader} />
          ) : packages?.length > 0 ? (
            <Row className={styles.pricing__wrapper}>
              {packages?.map((e, i) => (
                <Col md={4} key={i}>
                  <PricingCard
                    item={e}
                    setSelected={setSelected}
                    setShowModal={setShowModal}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <NoData text={"No Packages Found"} />
          )}
        </div>
        <div className={styles.alignRight}>
                {!!packages?.length && totalData>recordsLimit && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalData / recordsLimit)}
                    setCurrentPage={(e)=>{
                      setPage(e)
                      getPackages(e)
                    }}
                    currentPage={page}
                  />
                )}
              </div>
        {showModal && (
          <EditPackageModal
            show={showModal}
            setShow={setShowModal}
            data={selected}
            setPackages={setPackages}
            packages={packages}
          />
        )}
      </Container>
    </SideBarSkeleton>
  );
};

export default Packages;
