/* eslint-disable */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, NavItem, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import classes from "./Salary.module.css";
import { registeredUsers } from "../../config/DummyData";
import { useNavigate } from "react-router-dom";
import SalaryDetailModal from "../../modals/SalaryDetailModal";
const statusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Unpaid",
    value: "notPaid",
  },
  
];
const SalaryData=Array(8).fill({
  id:1,
  teacherName:"Test Teacher",
  hours:"2",
  amount:"5000",
  numberOfClasses:"5",
  status:"paid"
})
const Salary = () => {
  const navigate = useNavigate();
  const { access_token } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(SalaryData);
  const [status, setStatus] = useState(statusOptions[0]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selected, setSelected] = useState(null);

  // getData
  async function getData(pg = page, sts = status?.value) {
    const url = BaseURL(
      `salary/all?status=${sts}&page=${pg}&limit=${recordsLimit}`
    );
    setLoading(true);
    const response = await Get(url, access_token);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 370px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton heading={"Salaries"}>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <div className={classes.alignRight}>
                  <DropDown
                    options={statusOptions}
                    setter={(e) => {
                      setStatus(e);
                      setPage(1);
                      getData(1,e?.value)
                    }}
                    customStyle={{ minWidth: "180px" }}
                    value={status}
                  />
                </div>
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            S.No
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "20%", textAlign: "start" }}
                          >
                            Teacher Name
                          </th>
                         
                          <th
                            className="cell100 column3"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            Hours
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            Amount
                          </th>

                          <th
                            className="cell100 column5"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            No. of Classes
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                           status
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "25%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className="table100-body js-pscroll ps ps--active-y">
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr className="row100 body" key={index}>
                                <td
                                  className="cell100 column1"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "20%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {[item?.teacher?.firstName,item?.teacher?.lastName].join(" ")}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "10%",
                                    textAlign: "left",
                                  }}
                                >
                                  {item?.hours} hrs
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    ${item?.amount}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.classes?.length}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                  {item?.status === "notPaid" ? "Unpaid" : "Paid"}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column5"
                                  style={{ width: "25%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    {/* <Button
                                      className={classes.viewDetailBtn}
                                      label={"Pay Now"}
                                      onClick={() => {
                                        setShowPayNow(true)
                                      }}
                                    /> */}
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Details"}
                                      onClick={() => {
                                        setSelected(item)
                                        setShowDetailModal(true)
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Salary Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && totalResults>recordsLimit && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e)=>{
                      setPage(e)
                    getData(e,status?.value)
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
            <SalaryDetailModal show={showDetailModal} setShow={setShowDetailModal} data={data} setData={setData} selected={selected}/>
          </Container>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default Salary;
