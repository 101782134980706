/* eslint-disable */

import React, { useState } from "react";
import classes from "./AddLessonMaterialModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import ModalSkeleton from "../ModalSkeleton";
import { toast } from "react-toastify";
import {
  CreateFormData,
  formRegEx,
  formRegExReplacer,
} from "../../config/apiUrl";
import UploadMultiplePdf from "../../Component/UploadMultiplePdf";
import UploadImageBox from "../../Component/UploadImageBox";
import UploadVideoBox from "../../Component/UploadVideoBox";

const AddLessonMaterialModal = ({
  show,
  setShow,
  handleSubmit,
  isLoading = false,
  type,
}) => {
  const [selectedPDFFiles, setSelectedPDFFiles] = useState([]);
  const [selectedVideoFiles, setSelectedVideoFiles] = useState([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);

  const HandleSubmitData = async () => {
    let params = {
      ...(type == "pdf" && { pdf: selectedPDFFiles }),
      ...(type == "image" && { images: selectedImageFiles }),
      ...(type == "video" && { video: selectedVideoFiles }),
    };

    for (let key in params) {
      if (
        params[key] == "" ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please fill the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()} field!`
        );
      }
    }
    const formData = await CreateFormData(params);
    await handleSubmit(formData, params);
  };
  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="700px"
        borderRadius="20px"
        header={`Add Lesson Material`}
      >
        <div className={classes.addProjectModal_main}>
          <Row className={classes.addProject_row}>
            <Col md={12}>
              {type == "pdf" && (
                <UploadMultiplePdf
                  state={selectedPDFFiles}
                  setter={setSelectedPDFFiles}
                />
              )}
              {type == "image" && (
                <UploadImageBox
                  state={selectedImageFiles}
                  setter={setSelectedImageFiles}
                />
              )}
              {type == "video" && (
                <UploadVideoBox
                  state={selectedVideoFiles}
                  setter={setSelectedVideoFiles}
                />
              )}
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={HandleSubmitData}
              className={classes.btn}
              label={isLoading ? "Submitting..." : "Submit"}
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddLessonMaterialModal;
