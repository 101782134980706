/* eslint-disable */

import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./AllCourses.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL, apiHeader, imageUrl } from "../../config/apiUrl";
import moment from "moment";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import CourseCard from "../../Component/CourseCard/CourseCard";
import AddOrEditCourseModal from "../../modals/AddOrEditCourseModal";
import { toast } from "react-toastify";

const AllCourses = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const url = BaseURL(`course`);
  const [category, setCategory] = useState("regular");
  const [showAddorEditModal, setShowAddorEditModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  // getCourseData
  const getCourseData = async () => {
    const apiUrl = `${url}/all?category=${category}`;
    setIsLoading(true);
    const response = await Get(apiUrl, accessToken);

    if (response !== undefined) {
      setCourseData(response?.data?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCourseData();
  }, [category]);

  const HandleSubmitData = async (params) => {
    const apiUrl = selectedCourse
      ? `${url}/${selectedCourse?.slug}`
      : `${url}/create-course`;
    setSubmitLoading(true);
    const response = selectedCourse
      ? await Patch(apiUrl, params, apiHeader(accessToken, true))
      : await Post(apiUrl, params, apiHeader(accessToken, true));

    if (response !== undefined) {
      if (params?.category == category) {
        const oldData = [...courseData];
        selectedCourse
          ? oldData.splice(selectedCourse?.index, 1, response?.data?.data)
          : oldData.push(response?.data?.data);
        setCourseData(oldData);
      }
      toast.success(`Course ${courseData ? "Updated" : "Added"} Successfully`);
      setSelectedCourse(null);
      setShowAddorEditModal(false);
    }
    setSubmitLoading(false);
  };

  return (
    <div>
      <SideBarSkeleton heading={"All Courses"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row className={classes.row}>
              <Col md={12} className={classes?.alignRight}>
                {/* <h5 className={classes?.mainHeading}>All Courses</h5> */}
                <div className={classes?.btnContainer}>
                  <div title="Get Regular Courses">
                    <Button
                      className={[
                        classes.acceptBtn,
                        category !== "regular" && classes?.disabled,
                      ].join(" ")}
                      label={"Regular Course"}
                      onClick={() => {
                        setCategory("regular");
                      }}
                    />
                  </div>
                  <div title="Get Additional Courses">
                    <Button
                      className={[
                        classes.acceptBtn,
                        category !== "additional" && classes?.disabled,
                      ].join(" ")}
                      label={"Additional Course"}
                      onClick={() => {
                        setCategory("additional");
                      }}
                    />
                  </div>
                  <div title="Get Other Course">
                    <Button
                      className={[
                        classes.acceptBtn,
                        category !== "other" && classes?.disabled,
                      ].join(" ")}
                      label={"Other Course"}
                      onClick={() => {
                        setCategory("other");
                      }}
                    />
                  </div>
                  <div title="Add New Course">
                    <Button
                      className={[classes.addCourseBtn].join(" ")}
                      label={"Add Course"}
                      onClick={() => {
                        setShowAddorEditModal(true);
                      }}
                    />
                  </div>
                </div>
              </Col>
              {courseData?.length > 0 ? (
                courseData?.map((item, index) => (
                  <Col md={4} key={`courses-${index}`}>
                    <CourseCard
                      data={item}
                      onClick={() => {
                        navigate(`/course/${item?.slug}`);
                      }}
                      onClickEdit={(e) => {
                        e.stopPropagation();
                        setSelectedCourse({ ...item, index });
                        setShowAddorEditModal(true);
                      }}
                    />
                  </Col>
                ))
              ) : (
                !isLoading && <NoData text={"No Course Found"} />
              )}
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
      {showAddorEditModal && (
        <AddOrEditCourseModal
          show={showAddorEditModal}
          setShow={setShowAddorEditModal}
          handleSubmit={HandleSubmitData}
          data={selectedCourse}
          isLoading={submitLoading}
        />
      )}
    </div>
  );
};

export default AllCourses;
