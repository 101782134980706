const SideBarMenu = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    name: "Family",
    path: "/family/all",
  },
  {
    name: "Teachers",
    path: "/teacher/all",
  },
  {
    name: "Sub-Admin",
    path: "/sub-admin/all",
  },
  {
    name: "Transactions",
    path: "/transactions",
  },
  {
    name: "All Users",
    path: "/all-users",
  },
  {
    name: "Awaiting Acceptance",
    path: "/awaiting-acceptance",
  },
  {
    name: "New Tasks",
    path: "/new-tasks",
  },
  {
    name: "Archive",
    path: "/archive",
  },
  {
    name: "Packages",
    path: "/packages",
  },
  {
    name: "Chat",
    path: "/chat",
  },
  {
    name: "My Profile",
    path: "/my-profile",
  },
  {
    name: "Notifications",
    path: "/notifications",
  },
];

const genderData = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "othero",
  },
];

const maritalStatusData = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Divorced",
    value: "divorced",
  },
  {
    label: "Widowed",
    value: "widowed",
  },
];
const roles = ["parent", "teacher", "student"];

const registeredUsers = Array(10)
  .fill()
  .map(() => ({
    // Common
    email: "teacher@gmail.com",
    firstName: "Teacher",
    lastName: roles[Math.floor(Math.random() * roles.length)],
    photo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&usqp=CAU",
    phone: "08012345678",
    altPhone: "08012345678",
    timezone: "Africa/Lagos",
    address: "No 1, Teacher Street, Teacher Town Teacher State Teacher Country",
    nationality: "Nigerian",
    gender: "male",
    age: "30",
    currency: "USD",
    role: roles[Math.floor(Math.random() * roles.length)],
    isActive: true,
    isBlockedByAdmin: false,
    createdAt: Date.now(),

    // admin  + teacher
    CNIC: "https://i.pinimg.com/originals/be/84/ae/be84aeba62f8faf4a04bd2d6967c6360.png",
    materialStatus: true,

    // parent
    country: "Nigeria",
    city: "Lagos",

    // teacher
    qualification: "Bachelors",
    experience: "5 years",
    courses: Array(5).fill({
      _id: "1",
      title: "Mathematics",
      category: "regular", // additional, other
    }),
    schedule: [],
    amountPerHours: 10,
    reviews: [],
  }));

// const packagesData = [
//   {
//     id: 1,
//     packageType: "Premium",
//     description: ["abc abc abc", "xyz xyx xyz"],
//     noOfClasses: 4,
//     price: 3000,
//     isActive:
//   },
//   {
//     id: 2,
//     packageType: "Platinum",
//     description: ["abc abc abc", "xyz xyx xyz"],

//     noOfClasses: 4,
//     price: 4000,
//   },
//   {
//     id: 3,
//     packageType: "Silver",
//     description: ["abc abc abc", "xyz xyx xyz"],

//     noOfClasses: 4,
//     price: 5000,
//   },
//   {
//     id: 4,
//     packageType: "Platinum",
//     description: ["abc abc abc", "xyz xyx xyz"],
//     noOfClasses: 4,
//     price: 6000,
//   },
// ];
export { SideBarMenu, genderData, maritalStatusData, registeredUsers };
