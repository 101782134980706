import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { apiHeader, BaseURL } from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import { useSelector } from "react-redux";
import classes from "./EditPackageModal.module.css";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import Switch from "../../Component/Switch/Switch";
import { DropDown } from "../../Component/DropDown/DropDown";
const packageTypeData = [
  {
    label: "30 Minutes",
    value: "30",
  },
  {
    label: "60 Minutes",
    value: "60",
  },
];
const noOfClassesData = [
  {
    label: "4",
    value: "4",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "12",
    value: "12",
  },
  {
    label: "16",
    value: "16",
  },
  {
    label: "20",
    value: "20",
  },
];
const EditPackageModal = ({ show, setShow, data, setPackages, packages }) => {
  const { access_token } = useSelector((state) => state.authReducer);
  const [priceInUsd, setPriceInUsd] = useState(
   data ? data?.prices?.find((p) => (p.current = "usd"))?.amount:null
  );
  const [priceInPond, setPriceInPound] = useState(data? 
    data?.prices?.find((p) => (p.current = "gbp"))?.amount :null
  );
  const [packageType, setPackageType] = useState(
    data ? packageTypeData?.find((i) => i.value == data?.packageType):null
  );
  const [noOfClasses, setNoOfClasses] = useState(data ? noOfClassesData?.find(i=>i.value==data?.numberOfClasses):null);
  const [status, setStatus] = useState(data?.isActive);
  const [description, setDescription] = useState(
    data ? data.description : [""]
  );
  const [isLoading, setIsLoading] = useState(false);


  const addPackage = async () => {
    const reqType = data ? Patch : Post;
    const params = {
      packageType: packageType?.value,
      prices: [
        {
          currency: "usd",
          amount: priceInUsd,
        },
        {
          currency: "gbp",
          amount: priceInPond,
        },
      ],
      description,
      numberOfClasses: noOfClasses?.value,
      ...(data && { isActive: status }),
    };
    console.log("🚀 ~ file: index.js:29 ~ addPackage ~ params:", params);

    // validate
    for (let key in params){
      if (params[key] === "" || params[key]===null || params[key]===undefined) return toast.error(`Fields can not be empty.`);
      if(Array.isArray(params[key])){
        for(let i in params[key]){
          if(params[key][i] === "" || params[key][i]===null || params[key][i]===undefined) return toast.error(`Fields can not be empty.`);
        }
      }
    }
    if (priceInPond <= 0 || priceInUsd <= 0 || noOfClasses <= 0) {
      return toast.error(`Price Should be greater than 0`);
    }
    // call api
    const url = BaseURL(
      data ? `package/${data?.slug}` : `package/create-package`
    );
    setIsLoading(true);
    const response = await reqType(url, params, apiHeader(access_token));
    setIsLoading(false);

    if (response != undefined) {
      toast.success(`Package Successfully ${data ? "Updated" : "Created"}`);
      setShow(false);
      const temp = [...packages];
      if (!data) {
        temp.splice(temp.length - 1, 0, response?.data?.data);
      } else {
        temp.splice(
          temp.findIndex((i) => i?._id == response?.data?.data?._id),
          1,
          response?.data?.data
        );
      }
      setPackages(temp);
    }
  };
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={data ? "Edit Package" : "Create Package"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={classes.row}>
        {data && (
          <Col md={12}>
            <Switch value={status} setter={setStatus} />
            <label className={classes.label}>
              {status ? "Active" : "Deactive"}
            </label>
          </Col>
        )}
        <Col md={12}>
          <DropDown
            label={"Package Type"}
            placeholder={"Select Package Type"}
            options={packageTypeData}
            value={packageType}
            setter={setPackageType}
          />
          {/* <Input
            label={"Package Type"}
            placeholder={"Enter Package Type"}
            form
            value={packageType}
            setter={setPackageType}
          /> */}
        </Col>
        <Col md={12}>
          <Input
            type="number"
            label={`Price in USD`}
            placeholder={`Enter Price in USD`}
            form
            value={priceInUsd}
            setter={setPriceInUsd}
          />
        </Col>
        <Col md={12}>
          <Input
            type="number"
            label={`Price in Pound`}
            placeholder={`Enter Price in Pound`}
            form
            value={priceInPond}
            setter={setPriceInPound}
          />
        </Col>
        <Col md={12}>
          {/* <Input
            type="number"
            label={`No Of Classes`}
            placeholder={`Enter No Of Classes`}
            form
            value={noOfClasses}
            setter={setNoOfClasses}
          /> */}

          <DropDown
            label={`No Of Classes`}
            placeholder={`Enter No Of Classes`}
            options={noOfClassesData}
            value={noOfClasses}
            setter={setNoOfClasses}
          />
        </Col>
        <Col md={12} className={classes.header}>
          <h6>Description</h6>
          <div
            className={classes.addMoreWrap}
            onClick={() => {
              setDescription((prev) => [...prev, ""]);
            }}
          >
            <BsFillPlusCircleFill size={18} color={"var(--clr-secondary)"} />
            <span className={classes.addMore}>Add more</span>
          </div>
        </Col>
        {description?.map((element, index) => (
          <Col key={index} md={12} className={classes.desc}>
            <Input
              placeholder={"Enter Description"}
              form
              value={element}
              customStyle={{
                paddingRight: "25px",
              }}
              setter={(e) => {
                const temp = [...description];
                temp[index] = e;
                setDescription(temp);
              }}
            />
            {description.length > 1 && (
              <MdCancel
                size={25}
                className={classes.cancel}
                onClick={() => {
                  setDescription(description.filter((city, i) => i !== index));
                }}
              />
            )}
          </Col>
        ))}
        <Col md={12}>
          <Button
            variant="secondary"
            className={classes.loginBtn}
            customStyle={{
              minWidth: "120px",
            }}
            onClick={addPackage}
            disabled={isLoading}
            label={isLoading ? "wait..." : data ? "Update" : "Create"}
          />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default EditPackageModal;
