import React from "react";

import classes from "./StudentCard.module.css";
import { imageUrl } from "../../config/apiUrl";

const StudentCard = ({ data, onClick }) => {
  return (
    <div className={classes?.studentMain} onClick={onClick}>
      <div className={classes?.imageContainer}>
        <img src={imageUrl(data?.photo)} alt="student" />
      </div>
      <div className={classes?.infoContainer}>
        <h5>{`${data?.firstName} ${data?.lastName}`}</h5>
        <p>
          <b>Email: </b>
          {data?.email}
        </p>
        <p>
          <b>Phone: </b>
          {data?.phone}
        </p>
        <p>
          <b>Timezone: </b>
          {data?.timezone || "N/A"}
        </p>
        <p>
          <b>Nationality: </b>
          {data?.nationality}
        </p>
        <p>
          <b>Gender: </b>
          {data?.gender}
        </p>
      </div>
    </div>
  );
};

export default StudentCard;
