import React, { useState } from "react";
import classes from "./AddAnnouncementModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import ModalSkeleton from "../ModalSkeleton";
import { DropDown } from "../../Component/DropDown/DropDown";
import { TextArea } from "../../Component/TextArea";
import { Post } from "../../Axios/AxiosFunctions";
import { BaseURL, apiHeader, validate } from "../../config/apiUrl";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { CiCalendarDate } from "react-icons/ci";
import { toast } from "react-toastify";

const AddAnnouncementModal = ({
  show,
  setShow,
  //   handleSubmit,
  data,
  setData,
}) => {
  const [title, setTitle] = useState("");
  const [roles, setRoles] = useState([]);
  const [description, setDescription] = useState("");
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const userData = useSelector((state) => state.authReducer.user);
  const [loader, setLoader] = useState(false);

  const HandleSubmitData = async () => {
    const data = {
      title,
      description,
      roles: roles?.map((item) => item.value),
      startDate,
      endDate,
    };
    const validationError = validate(data);
    console.log(validationError,"validationError validationError validationError")
    if (validationError) {
      return;
    }
    setLoader(true);
    const response = await Post(
      BaseURL("announcement/create"),
      data,
      apiHeader(accessToken)
    );

    if (response != undefined) {
      toast.success("Announcement Added Successfully");
      setShow(false);
      setData((prev) => [response?.data?.data, ...prev]);
      // handleSubmit();
    }
    setLoader(false);
  };

  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="1000px"
        borderRadius="20px"
        header={`${data ? "Edit" : "Add"} Announcement`}
      >
        <div className={classes.addProjectModal_main}>
          <Row className={classes.addProject_row}>
            <Col md={6}>
              <div className={classes.datePicker_input}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date()}
                />
                <div className={classes?.icon}>
                  <CiCalendarDate />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.datePicker_input}>
                <DatePicker
                  minDate={new Date()}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                />
                <div className={classes?.icon}>
                  <CiCalendarDate />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <DropDown
                isMulti
                setter={(e) => {
                  setRoles(e);
                }}
                value={roles}
                options={[
                  // {
                  //   label: "Parent",
                  //   value: "parent",
                  // },
                  ...(userData?.role !== "subAdmin"
                    ? [
                        {
                          label: "Sub Admin",
                          value: "subAdmin",
                        },
                      ]
                    : []),

                  {
                    label: "Teacher",
                    value: "teacher",
                  },
                  {
                    label: "Student",
                    value: "student",
                  },
                ]}
                placeholder={"Select Roles"}
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option?.value}
              />
            </Col>

            <Col md={12}>
              <div className="mt-3">
                <Input setter={setTitle} value={title} placeholder={"Title"} />
              </div>
            </Col>
            <Col md={12}>
              <div className="mt-3">
                <TextArea
                  placeholder={"Description"}
                  value={description}
                  setter={setDescription}
                />
              </div>
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={HandleSubmitData}
              className={classes.btn}
              label={loader ? "Submitting..." : "Submit"}
              disabled={loader}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddAnnouncementModal;
