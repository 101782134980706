/* eslint-disable */

import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./LessonLearningMaterial.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL, apiHeader } from "../../config/apiUrl";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import { toast } from "react-toastify";
import AddLessonMaterialModal from "../../modals/AddLessonMaterialModal";
import MaterialCard from "../../Component/MaterialCard/MaterialCard";
import axios from "axios";
import AreYouSureModal from "../../modals/AreYouSureModal";

const LessonLearningMaterial = () => {
  const data = useLocation()?.state;
  const { courseSlug, lessonSlug } = useParams();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [materialData, setMaterialData] = useState(data || null);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const url = BaseURL(`course`);
  const materialUrl = `${url}/lesson/material/${lessonSlug}`;
  const [materialType, setMaterialType] = useState("pdf");

  // getLessonMaterial
  const getLessonMaterial = async () => {
    const apiUrl = `${url}/lesson/${courseSlug}`;
    setIsLoading(true);
    const response = await Get(apiUrl, accessToken);

    if (response !== undefined) {
      setMaterialData(response?.data?.data?.material);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (data == null) getLessonMaterial();
  }, []);

  const HandleSubmitData = async (formData, params) => {
    const data =
      materialType == "video"
        ? { videoCount: params?.video?.length }
        : formData;
    setSubmitLoading(true);
    const response = await Post(
      materialUrl,
      data,
      apiHeader(accessToken, true)
    );

    if (response !== undefined) {
      materialType == "video" &&
        (await HandleSubmitPreSignedUrl(
          response?.data?.data?.videosUrl,
          params?.video
        ));
      setMaterialData(response?.data?.data?.material);
      navigate(`/course/${courseSlug}/${lessonSlug}`, {
        state: response?.data?.data?.material,
      });
      toast.success(`Material Added Successfully`);
      setShowAddMaterialModal(false);
    }
    setSubmitLoading(false);
  };

  // uploading videos
  const HandleSubmitPreSignedUrl = async (urlsArray, videos) => {
    const promises = urlsArray.map((url, index) => {
      return axios.put(url, videos[index], {
        headers: {
          "Content-Type": videos[index]?.type,
        },
        onUploadProgress: (data) => {
          setVideoProgress(
            Math.round(((100 * data.loaded) / videos.total) * (index + 1))
          );
        },
      });
    });
    const results = await Promise.allSettled(promises);
    return results;
  };

  const HandleSubmitDeleteMaterial = async () => {
    const params = {
      itemKey: selectedMaterial?.item,
      type: selectedMaterial?.type,
    };
    setSubmitLoading(true);
    const response = await Patch(materialUrl, params, apiHeader(accessToken));

    if (response !== undefined) {
      const oldData = [...materialData];
      oldData.splice(selectedMaterial?.index, 1);
      setMaterialData(oldData);
      navigate(`/course/${courseSlug}/${lessonSlug}`, {
        state: oldData,
      });
      toast.success(`Material Deleted Successfully`);
      setSelectedMaterial(null);
      setShowAreYouSureModal(false);
    }
    setSubmitLoading(false);
  };

  return (
    <div>
      <SideBarSkeleton>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row>
              <Col md={12} className={classes?.headerContainer}>
                <h5 className={classes?.mainHeading}>Lesson Material</h5>
                <div className={classes?.btnContainer}>
                  <div title="All PDF Learning Material">
                    <Button
                      className={[
                        classes.acceptBtn,
                        materialType !== "pdf" && classes?.disabled,
                      ].join(" ")}
                      label={"PDF"}
                      onClick={() => {
                        setMaterialType("pdf");
                      }}
                    />
                  </div>
                  <div title="All Videos Learning Material">
                    <Button
                      className={[
                        classes.acceptBtn,
                        materialType !== "video" && classes?.disabled,
                      ].join(" ")}
                      label={"Videos"}
                      onClick={() => {
                        setMaterialType("video");
                      }}
                    />
                  </div>
                  <div title="All Images Learning Material">
                    <Button
                      className={[
                        classes.acceptBtn,
                        materialType !== "image" && classes?.disabled,
                      ].join(" ")}
                      label={"Images"}
                      onClick={() => {
                        setMaterialType("image");
                      }}
                    />
                  </div>
                  <div title="Add New Material">
                    <Button
                      className={[classes.addCourseBtn].join(" ")}
                      label={"Add Material"}
                      onClick={() => {
                        setShowAddMaterialModal(true);
                      }}
                    />
                  </div>
                </div>
              </Col>
              {materialData?.length > 0 &&
                materialData?.map(
                  (item, index) =>
                    item?.type == materialType && (
                      <Col md={4} key={`lessson-${index}`}>
                        <MaterialCard
                          data={item}
                          onClickDelete={(e) => {
                            e.stopPropagation();
                            setSelectedMaterial({ ...item, index });
                            setShowAreYouSureModal(true);
                          }}
                        />
                      </Col>
                    )
                )}
              {materialData.filter((e) => e?.type == materialType)?.length ==
                0 && <NoData text={"No Lesson Material Found"} />}
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
      {showAddMaterialModal && (
        <AddLessonMaterialModal
          type={materialType}
          show={showAddMaterialModal}
          setShow={setShowAddMaterialModal}
          handleSubmit={HandleSubmitData}
          data={selectedMaterial}
          isLoading={submitLoading}
        />
      )}
      {showAreYouSureModal && (
        <AreYouSureModal
          show={showAreYouSureModal}
          setShow={setShowAreYouSureModal}
          subTitle={"Are you sure you want to delete this material?"}
          onClick={HandleSubmitDeleteMaterial}
          isApiCall={submitLoading}
        />
      )}
    </div>
  );
};

export default LessonLearningMaterial;
