import React, { useState } from "react";
import classes from "./AddOrEditCourseModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import ModalSkeleton from "../ModalSkeleton";
import { toast } from "react-toastify";
import { DropDown } from "../../Component/DropDown/DropDown";
import { TextArea } from "../../Component/TextArea";
import { formRegEx, formRegExReplacer } from "../../config/apiUrl";
import UploadImageBox from "../../Component/UploadImageBox";

const AddOrEditCourseModal = ({
  show,
  setShow,
  handleSubmit,
  isLoading = false,
  data,
}) => {
  const [title, setTitle] = useState(data?.title || "");
  const [category, setCategory] = useState(
    data?.category ? { label: data?.category, value: data?.category } : ""
  );
  const [minimumAge, setMinimumAge] = useState(data?.minimumAge || "");
  const [description, setDescription] = useState(data?.description || "");
  const [coverImage, setCoverImage] = useState(data?.coverImage || null);

  const HandleSubmitData = async () => {
    let params = {
      title,
      category: category?.value,
      minimumAge,
      description,
      coverImage,
    };

    for (let key in params) {
      if (
        params[key] == "" ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please fill the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()} field!`
        );
      }
    }
    await handleSubmit(params);
  };
  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="700px"
        borderRadius="20px"
        header={`${data ? "Edit" : "Add"} Course`}
      >
        <div className={classes.addProjectModal_main}>
          <Row className={classes.addProject_row}>
            <Col md={12}>
              <UploadImageBox
                state={coverImage}
                setter={setCoverImage}
                label="Cover Image"
                hideDeleteIcon={false}
                isMultiple = {false}
                // imgClass={classes.boxImageClass}
              />
            </Col>
            <Col md={12}>
              <Input
                setter={setTitle}
                value={title}
                placeholder={"enter title..."}
                label={"Title"}
              />
            </Col>
            <Col md={12}>
              <DropDown
                setter={setCategory}
                value={category}
                options={["regular", "additional", "other"].map((item) => ({
                  label: item,
                  value: item,
                }))}
                placeholder={"Select Category"}
                // getOptionLabel={(option) => option}
                // getOptionValue={(option) => option}
                label={"Category"}
              />
            </Col>
            <Col md={12}>
              <Input
                setter={setMinimumAge}
                value={minimumAge}
                type={"Number"}
                placeholder={"Enter Minimum Age..."}
                label={"Minimum Age"}
              />
            </Col>
            <Col md={12}>
              <TextArea
                placeholder={"Enter Description..."}
                value={description}
                setter={setDescription}
                label={"Description"}
              />
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={HandleSubmitData}
              className={classes.btn}
              label={isLoading ? "Submitting..." : "Submit"}
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddOrEditCourseModal;
