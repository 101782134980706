/* eslint-disable */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { returnColoredStatus } from "../../constant/commonFunctions";
import classes from "./ListOfAllTeachers.module.css";
import { registeredUsers } from "../../config/DummyData";
import { useNavigate } from "react-router-dom";
const statusOptions = [
  {
    label: "All",
    value: "all",
  },

  {
    label: "Active",
    value: true,
    status: "Active",
  },
  {
    label: "De Active",
    value: false,
    status: "Deactive",
  },
];

const ListOfAllTeachers = () => {
  const navigate = useNavigate();
  const { access_token } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(statusOptions[0]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [btnLoading, setBtnLoading] = useState("");

  // handleStatus
  const handleStatus = async (sts, id, slug) => {
    const params = {
      status: sts,
    };
    setBtnLoading(id);
    const response = await Patch(
      BaseURL(`admin/block-unblock-user/${slug}`),
      params,
      apiHeader(access_token)
    );
    if (response !== undefined) {
      const newData = [...data];
      console.log(status?.value, "aaaa");
      if (status?.value === "all") {
        newData?.splice(
          newData?.findIndex((item) => item?._id == response?.data?.data?._id),
          1,
          response?.data?.data
        );
        console.log("newData ", newData);
      } else {
        newData?.splice(
          newData?.findIndex((item) => item?._id == response?.data?.data?._id),
          1
        );
      }
      setData(newData);
      toast.success(
        `User has been ${!sts ? "Activated" : "Deactivated"} successfully`
      );
    }
    setBtnLoading("");
  };

  // getData
  async function getData(pg = page, sts = status?.value) {
    const url = BaseURL(
      `admin/all-users?status=${sts}&page=${pg}&limit=${recordsLimit}&role=teacher`
    );
    setLoading(true);
    const response = await Get(url, access_token);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 370px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton heading={"Teachers"}>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <div className={classes.alignRight}>
                  {/* <h5>Teachers</h5> */}
                  <div className="d-flex">
                    <Button
                      label={"Add New Teacher"}
                      className={"me-3"}
                      onClick={() => navigate(`/teacher/new`)}
                    />
                    <DropDown
                      options={statusOptions}
                      setter={(e) => {
                        setStatus(e);
                        setPage(1);
                        getData(1, e?.value);
                      }}
                      customStyle={{ minWidth: "180px" }}
                      value={status}
                    />
                  </div>
                </div>
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "6%", textAlign: "left" }}
                          >
                            S.No
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "15%", textAlign: "left" }}
                          >
                            Name
                          </th>
                          <th
                            className="cell100 column3"
                            style={{ width: "20%", textAlign: "left" }}
                          >
                            Email
                          </th>
                          <th
                            className="cell100 column3"
                            style={{ width: "15%", textAlign: "left" }}
                          >
                            Member Since
                          </th>

                          <th
                            className="cell100 column4"
                            style={{ width: "10%", textAlign: "left" }}
                          >
                            Status
                          </th>

                          <th
                            className="cell100 column5"
                            style={{ width: "34%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className={`table100-body js-pscroll ps ps--active-y`}>
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr
                                key={index}
                                className="row100 body"
                                style={
                                  {
                                    // backgroundColor:
                                    // selectedItem?._id == item?._id && "#E5F5FF",
                                  }
                                }
                              >
                                <td
                                  className="cell100 column1"
                                  style={{ width: "6%", textAlign: "left" }}
                                >
                                  {/* {index + 1 + (page - 1) * recordsLimit} */}
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "15%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={`${classes.descCol} t-t-c`}>
                                    {[item?.firstName, item?.lastName].join(
                                      " "
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "20%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.email}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.createdAt).format("lll")}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  {returnColoredStatus(
                                    item?.isBlockedByAdmin
                                      ? "rejected"
                                      : "active",
                                    item?.isBlockedByAdmin
                                      ? "Deactive"
                                      : "Active"
                                  )}
                                </td>
                                <td
                                  className="cell100 column5"
                                  style={{ width: "34%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <div title="Click to View Teacher detail">
                                      <Button
                                        className={classes.viewDetailBtn}
                                        label={"Detail"}
                                        onClick={() => {
                                          navigate(`/user/${item?.slug}`);
                                        }}
                                      />
                                    </div>
                                    <div title="Click to View Children">
                                      <Button
                                        className={classes.viewDetailBtn}
                                        label={"Edit"}
                                        onClick={() => {
                                          navigate(`/teacher/edit`, {
                                            state: { item },
                                          });
                                        }}
                                      />
                                    </div>

                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={
                                        btnLoading === item?._id
                                          ? "Wait..."
                                          : item?.isBlockedByAdmin
                                          ? "Activate"
                                          : "Deactivate"
                                      }
                                      onClick={() => {
                                        handleStatus(
                                          item?.isBlockedByAdmin ? false : true,
                                          item?._id,
                                          item?.slug
                                        );
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Teacher Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && totalResults > recordsLimit && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e, status?.value);
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default ListOfAllTeachers;
