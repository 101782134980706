/* eslint-disable */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import classes from "./AllClasses.module.css";
import { registeredUsers } from "../../config/DummyData";
import { useNavigate } from "react-router-dom";
const statusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Completed",
    value: "previous",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

const AllClasses = () => {
  const navigate = useNavigate();
  const { access_token } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(statusOptions[0]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  // getData
  async function getData(pg = page, sts = status?.value) {
    const url = BaseURL(
      `admin/get-classes?status=${sts}&page=${pg}&limit=${recordsLimit}`
    );
    setLoading(true);
    const response = await Get(url, access_token);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 370px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }
      `}</style>
      <div>
        <SideBarSkeleton heading={"All Classes"}>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <div className={classes.alignRight}>
                  <DropDown
                    options={statusOptions}
                    setter={(e) => {
                      setStatus(e);
                      setPage(1);
                      getData(1,e?.value)
                    }}
                    customStyle={{ minWidth: "180px" }}
                    value={status}
                  />
                </div>
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "9%", textAlign: "start" }}
                          >
                            S.No
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "22%", textAlign: "start" }}
                          >
                            Student Name
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "22%", textAlign: "start" }}
                          >
                            Teacher Name
                          </th>
                          <th
                            className="cell100 column3"
                            style={{ width: "22%", textAlign: "start" }}
                          >
                            Timing
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Type
                          </th>

                          <th
                            className="cell100 column5"
                            style={{ width: "10%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className="table100-body js-pscroll ps ps--active-y">
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr className="row100 body" key={index}>
                                <td
                                  className="cell100 column1"
                                  style={{ width: "9%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "22%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.student?.firstName}{" "}
                                    {item?.student?.lastName}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "22%",
                                    textAlign: "start",
                                  }}
                                >
                                  {item?.teacher?.firstName || "-"}{" "}
                                  {item?.teacher?.lastName}
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "22%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.startTime, "HH:mm").format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.classType}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column5"
                                  style={{ width: "10%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Details"}
                                      onClick={() => {
                                        navigate({
                                          pathname: `/class/${item?._id}`,
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Classes Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && totalResults>recordsLimit && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e)=>{
                      setPage(e)
                      getData(e,status?.value)

                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default AllClasses;
