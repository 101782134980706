import React from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./RenderMediaModal.module.css";
import modalClasses from "../ModalSkeleton/modalSkeleton.module.css";
import { RxCross2 } from "react-icons/rx";
import { imageUrl, pdfUrl } from "../../config/apiUrl";
const RenderMediaModal = ({ fileKey, type, hideToolBar, show, setShow }) => {
  return (
    <div>
      <style>
        {`
        .${modalClasses.body} {
          padding: 10px;
        }
        `}
      </style>
      <ModalSkeleton
        setShow={setShow}
        show={show}
        width={"1162px"}
        borderRadius={"20px"}
      >
        <div className={classes.main}>
          {type === "pdf" && (
            <div className={classes?.pdfContainer}>
              <iframe
                src={pdfUrl(`${fileKey}${hideToolBar ? "#toolbar=0" : ""}`)}
                type="application/pdf"
                width="100%"
                height="100%"
                id="pdfFrame"
                seamless="seamless"
              ></iframe>
            </div>
          )}
          {type === "image" && (
            <div className={classes?.imageContainer}>
              <img src={imageUrl(fileKey)} />
            </div>
          )}
          {type === "video" && (
            <div className={classes?.videoContainer}>
              <video controls>
                <source src={fileKey} />
              </video>
            </div>
          )}
          <div
            className={classes.closeBtn}
            onClick={() => {
              setShow(false);
            }}
          >
            <RxCross2 size={20} color={"var(--white-color"} />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default RenderMediaModal;
