/* eslint-disable */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { toast } from "react-toastify";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import classes from "./MyProfile.module.css";
import { FaEdit } from "react-icons/fa";
import UpdatePasswordModal from "../../modals/UpdatePasswordModal";
import { Patch } from "../../Axios/AxiosFunctions";
import { useSelector, useDispatch } from "react-redux";
import {
  apiHeader,
  BaseURL,
  capitalizeFirstLetter,
  CreateFormData,
  formRegEx,
  formRegExReplacer,
} from "../../config/apiUrl";
import { updateUser } from "../../store/auth/authSlice";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import { TimezoneDropDown } from "../../Component/TimezoneDropDown/TimezoneDropDown";
import { TextArea } from "../../Component/TextArea";
import { Radio } from "../../Component/Radio/Radio";
import { DropDown } from "../../Component/DropDown/DropDown";
import Maps from "../../Component/MapAndPlaces";
import PhoneInputCompo from "../../Component/PhoneInputCompo";
import { Country, City } from "country-state-city";
import validator from "validator";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  console.log("user ", user);

  const [profileImg, setProfileImg] = useState(user?.photo ? user?.photo : "");
  const [email, setEmail] = useState(user?.email);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [phone, setPhone] = useState(user?.phone || "");
  const [altPhone, setAltPhone] = useState(user?.altPhone || "");
  const [coordinates, setCoordinates] = useState(
    user?.location
      ? {
          lat: user?.location?.coordinates?.[1],
          lng: user?.location?.coordinates?.[0],
        }
      : null
  );
  const [address, setAddress] = useState(user?.address || "");
  const [timeZone, setTimeZone] = useState(
    // user ? { value: user?.timeZone } : ""
    user?.timeZone || ""
  );
  const [nationality, setNationality] = useState(user?.nationality || "");
  const [gender, setGender] = useState(user?.gender || "");
  const [age, setAge] = useState(user?.age || "");
  const [description, setDescription] = useState(user?.description || "");
  const [maritalStatus, setMaritalStatus] = useState(
    user?.maritalStatus ? "married" : "single"
  );

  const [country, setCountry] = useState(user ? { name: user?.country } : "");
  const [city, setCity] = useState(user ? { name: user?.city } : "");
  const [CNIC, setCNIC] = useState(user?.CNIC || "");

  // other states
  const [showModal, setshowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // handle edit profile
  const handleEditProfile = async () => {
    const params = {
      photo: profileImg,
      firstName,
      lastName,
      phone,
      altPhone,
      timeZone: timeZone?.value,
      address,
      nationality,
      CNIC,
      maritalStatus,
      country: country?.name,
      city: city?.name,
      age,
      gender,
      description,
      lat: coordinates?.lat,
      lng: coordinates?.lng,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${capitalizeFirstLetter(
            key?.replace(formRegEx, formRegExReplacer)
          )} can not be empty.`
        );

    // validate phone
    if (!validator.isMobilePhone(altPhone))
      return toast.warn("Alt Phone number is not valid");

    // params.city = city?.name || "";

    // call api
    const formData = await CreateFormData(params);
    const url = BaseURL(`users/update-profile`);

    setIsLoading(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsLoading(false);

    if (response != undefined) {
      dispatch(updateUser(response?.data?.data?.user));
      toast.success("Profile updated successfully");
    }
  };

  return (
    <SideBarSkeleton>
      <div className={classes?.topContainer}>
        <Container className={classes.conatiner}>
          <Row className={classes.main}>
            <Col md={12}>
              <div className={classes.head}>
                <h5>Edit Profile</h5>
                <Button
                  label={"Edit Password"}
                  className={classes.editPasswordBtn}
                  rightIcon={<FaEdit size={20} />}
                  onClick={() => setshowModal(true)}
                />
              </div>
            </Col>
            <Col md={12}>
              <ProfileWithEditButton
                updateImage={profileImg}
                setUpdateImage={setProfileImg}
                isEdit={true}
                onClick={() => {}}
              />
            </Col>

            <Col md={12}>
              <Row className={classes.innerRowGap}>
                <Col md={12} xl={8} lg={10}>
                  <Input
                    setter={setEmail}
                    value={email}
                    placeholder={"Enter Email "}
                    label={"Email"}
                    type={"email"}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>

            <Col md={6} className={`mt-3`}>
              <Input
                setter={setFirstName}
                value={firstName}
                placeholder={"First Name Here"}
                label={"First Name"}
                type={"text"}
              />
            </Col>
            <Col md={6} className={`mt-3`}>
              <Input
                setter={setLastName}
                value={lastName}
                placeholder={"Last Name Here"}
                label={"Last Name"}
                type={"text"}
              />
            </Col>
            <Col md={6} className={`mt-3`}>
              <PhoneInputCompo
                label={"Phone number"}
                setter={setPhone}
                value={phone}
                disabled={true}
              />
            </Col>
            <Col md={6} className={`mt-3`}>
              <PhoneInputCompo
                label={"Alt phone number"}
                setter={setAltPhone}
                value={altPhone}
              />
            </Col>
            <Col md={6} className={`mt-3`}>
              <TimezoneDropDown
                value={timeZone}
                setter={setTimeZone}
                isSearchable={true}
                label={"Timezone"}
              />
            </Col>
            <Col md={6} className={`mt-3`}>
              <Maps
                label={"Address"}
                type="location"
                setCoordinates={setCoordinates}
                setAddress={setAddress}
                address={address}
              />
            </Col>
            <Col md={6} className={`mt-3`}>
              <Input
                setter={setNationality}
                value={nationality}
                placeholder={"Nationality Here"}
                label={"Nationality"}
                type={"text"}
              />
            </Col>
            {user?.role !== "student" && (
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setCNIC}
                  value={CNIC}
                  placeholder={"NIC Here"}
                  label={"NIC Number"}
                  type={"number"}
                />
              </Col>
            )}
            
                <Col md={6} className={`mt-3`}>
                  <DropDown
                    isSearchable={true}
                    label={"Select Country"}
                    options={Country.getAllCountries()}
                    optionLabel={"name"}
                    optionValue={"name"}
                    setter={(e) => {
                      setCountry(e);
                      setCity("");
                    }}
                    value={country}
                    customStyle={{ minWidth: "180px" }}
                    placeholder={"Select Country"}
                  />
                </Col>
                <Col md={6} className={`mt-3`}>
                  <DropDown
                    isSearchable={true}
                    label={"Select City"}
                    options={City.getCitiesOfCountry(country?.isoCode)}
                    optionLabel={"name"}
                    optionValue={"name"}
                    setter={(e) => {
                      setCity(e);
                    }}
                    value={city}
                    customStyle={{ minWidth: "180px" }}
                    placeholder={"Select City"}
                  />
                </Col>
            

           
              <Col md={6} className={`mt-3`}>
                <Input
                  setter={setAge}
                  value={age}
                  placeholder={"Age Here"}
                  label={"Age"}
                  type={"number"}
                />
              </Col>

            <Col md={6} className={`mt-3`}>
              <label className={classes?.customLabel}>Select Gender</label>
              <Radio label="male" value={gender} setValue={setGender} />
              <Radio label="female" value={gender} setValue={setGender} />
            </Col>
          
              <Col md={6} className={`mt-3`}>
                <label className={classes?.customLabel}>Marital Status</label>
                <Radio
                  label="married"
                  value={maritalStatus}
                  setValue={setMaritalStatus}
                />
                <Radio
                  label="single"
                  value={maritalStatus}
                  setValue={setMaritalStatus}
                />
              </Col>
            


            <Col md={12} className={`mt-3`}>
              <TextArea
                value={description}
                setter={setDescription}
                placeholder={"Enter description"}
                label={"Description"}
              />
            </Col>

            <Col md={12} xl={8} lg={10} sm={12}>
              <Button
                label={isLoading ? "Updating..." : "Edit Profile"}
                className={classes.editBtn}
                rightIcon={<FaEdit size={20} />}
                onClick={handleEditProfile}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Update password modal */}
      <UpdatePasswordModal show={showModal} setShow={setshowModal} />

      {isLoading && <LottieLoader />}
    </SideBarSkeleton>
  );
};

export default MyProfile;
