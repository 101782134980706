import React from "react";
import classes from "./LessonCard.module.css";
import { Button } from "../Button/Button";
import ShowMoreShowLessText from "../ShowMoreShowLess/ShowMoreShowLessText";
import { RiEdit2Fill } from "react-icons/ri";

const LessonCard = ({ data, selectedLesson, onClick, onClickEdit }) => {
  return (
    <div
      className={`${classes?.lessonCardMain} ${
        selectedLesson?._id === data?._id && classes?.selectedLessonMain
      }`}
    >
      <div className={classes?.lessonDetails}>
        <h3>{data?.title}</h3>
        <ShowMoreShowLessText
          text={data?.description}
          visibility={500}
          containerClass={classes?.lessonDescription}
        />
        <div className={classes?.lessonDetailsBottom}>
          <div className={classes?.lessonDetailsBottomLeft}>
            <p>
              <span>Course:</span> {data?.course?.title}
            </p>
          </div>
          <div className={classes?.lessonDetailsBottomRight}>
            <Button
              className={[classes.materialBtn].join(" ")}
              label={"View Material"}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
      <div className={classes.editIcon} onClick={onClickEdit}>
        <RiEdit2Fill size={20} />
      </div>
    </div>
  );
};

export default LessonCard;
