/* eslint-disable */

import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./AllLessons.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL, apiHeader, imageUrl } from "../../config/apiUrl";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import { toast } from "react-toastify";
import LessonCard from "../../Component/LessonCard/LessonCard";
import AddOrEditLessonModal from "../../modals/AddOrEditLessonModal";

const AllLessons = () => {
  const { courseSlug } = useParams();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [showAddorEditModal, setShowAddorEditModal] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const url = BaseURL(`course`);

  // getAllLessonOfCourse
  const getAllLessonOfCourse = async () => {
    const apiUrl = `${url}/lesson/${courseSlug}`;
    setIsLoading(true);
    const response = await Get(apiUrl, accessToken);

    if (response !== undefined) {
      setLessonData(response?.data?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllLessonOfCourse();
  }, []);

  const HandleSubmitData = async (params) => {
    const apiUrl = selectedLesson
      ? `${url}/lesson/${selectedLesson?.slug}`
      : `${url}/create-lesson`;
    setSubmitLoading(true);
    const response = selectedLesson
      ? await Patch(
          apiUrl,
          { ...params, course: courseSlug },
          apiHeader(accessToken)
        )
      : await Post(
          apiUrl,
          { ...params, course: courseSlug },
          apiHeader(accessToken)
        );

    if (response !== undefined) {
      const oldData = [...lessonData];
      selectedLesson
        ? oldData.splice(selectedLesson?.index, 1, response?.data?.data)
        : oldData.push(response?.data?.data);
      setLessonData(oldData);
      toast.success(`Course ${lessonData ? "Updated" : "Added"} Successfully`);
      setSelectedLesson(null);
      setShowAddorEditModal(false);
    }
    setSubmitLoading(false);
  };

  return (
    <div>
      <SideBarSkeleton heading={"Lessons"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row className={classes.row}>
              <Col md={12} className={classes?.alignRight}>
                {/* <h5 className={classes?.mainHeading}>Lessons</h5> */}
                <div className={classes?.btnContainer}>
                  <div title="Add New Lesson">
                    <Button
                      className={[classes.addCourseBtn].join(" ")}
                      label={"Add Lesson"}
                      onClick={() => {
                        setShowAddorEditModal(true);
                      }}
                    />
                  </div>
                </div>
              </Col>
              {lessonData?.length > 0 ? (
                lessonData?.map((item, index) => (
                  <Col md={4} key={`lessson-${index}`}>
                    <LessonCard
                      data={item}
                      onClick={() => {
                        navigate(`/course/${courseSlug}/${item?.slug}`, {
                          state: item?.material,
                        });
                      }}
                      onClickEdit={(e) => {
                        e.stopPropagation();
                        setSelectedLesson({ ...item, index });
                        setShowAddorEditModal(true);
                      }}
                    />
                  </Col>
                ))
              ) : (
                <NoData text={"No Lesson Found"} />
              )}
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
      {showAddorEditModal && (
        <AddOrEditLessonModal
          show={showAddorEditModal}
          setShow={setShowAddorEditModal}
          handleSubmit={HandleSubmitData}
          data={selectedLesson}
          isLoading={submitLoading}
        />
      )}
    </div>
  );
};

export default AllLessons;
