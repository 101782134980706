import { toast } from "react-toastify";

// export const apiUrl = "https://qarabic-backend.herokuapp.com";
export const apiUrl = "https://qarabic-backend-dem-ce8bb3e699dc.herokuapp.com";
export const awsUrl = "https://snakeyfy-development.s3.eu-west-3.amazonaws.com"; // here

export const socketURL = `${apiUrl}`;
export const stripe_public_key = `pk_test_51L8FqNL51LXb45sopLMv0wOiEZvg9NhshZWh0vWC7Okl7Alm2n01MGG89jdvovL41Fw98xNs1I0VqIn29KCD9JQc00acfYgJBk`;

// export const imageUrl = (url) => `${awsUrl}/${url}`;
export const imageUrl = (url) => `${apiUrl}/api/images/${url}`;
export const pdfUrl = (url) => `${apiUrl}/api/pdf/${url}`;
export const locationHelper = window.location.origin;
export const validate = (data) => {
  console.log(data, "data data");
  for (let key in data) {
    if (data[key] == undefined || data[key] == null || data[key] == "") {
      return toast.error(`Please Fill ${key}`);
    }
  }
};
export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let d in data[key]) {
        if (typeof data[key][d] == "string") {
          formData.append(key, data[key][d]);
        } else if (
          data[key][d] instanceof File ||
          data[key][d] instanceof Date
        ) {
          formData.append(key, data[key][d]);
        } else {
          formData.append(key, JSON.stringify(data[key][d]));
        }
      }
    } else if (typeof data[key] == "object") {
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const firebaseVapidObject = {
  vapidKey:
    "BKlmZ2T6iSDR1mOAaQOzBEbwHdlYX71OqZOwvvja_oDliCf9eRrb8Rgo_dasRs9WCpgSppH0O0kvtIOc959bO0U",
};
export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";
export const numberRegEx = /[^0-9]+/g;
export const exceptThisSymbols = ["e", "E", "+", "-", "."];

export let recordsLimit = 10;

export const googleMapApiKey = `AIzaSyDa3hGQ1LsGw7cyjCwCKx6rxU62g6vt0b8`;
