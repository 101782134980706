import React from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import classes from "./PhoneInput.module.css"


const PhoneInputCompo = ({
    label,
    label2, // sub label
    value, // input value
    setter, //setValue
    noBorder,
    placeholder,
    disabled,
    customStyle, //Input Container inline Style
    inputStyle, //Input inline Style
    labelStyle, //Label inline Style
    error, // Show Error Boolean
    errorText, // Error Text

    inputRef,
    className,
    innerClass,
}) => {
  return (
    <div
    className={`${[classes.Container, className && className].join(" ")}`}
  >
    {label && (
      <label
        htmlFor={`input${label}`}
        className={`${[
          classes.labelText,
          disabled && classes.disabled,
        ].join(" ")}`}
        style={{ ...labelStyle }}
      >
        {label} {label2 && label2}
      </label>
    )}
    <div
      className={`${[classes.inputPassContainer, innerClass].join(" ")}`}
      style={{ ...customStyle }}
    >
      <PhoneInput
    country={"us"}
    value={value}
        onChange={(e) => {
          setter(e);
        }}
        disabled={disabled}
        placeholder={placeholder}
        ref={inputRef}
        onBlur={() => {
          setter(value?.trim());
        }}
        style={{ ...inputStyle }}
        className={` ${[
          classes.inputBox,
          noBorder && classes.noBorder,
        ].join(" ")}`}
        id={`input${label}`}

  />
    

    </div>
    {error && (
      <p className={`mt-2 ${[classes.errorText].join(" ")}`}>{errorText}</p>
    )}
  </div>
 
  )
}

export default PhoneInputCompo