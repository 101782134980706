export function returnColoredStatus(status, statusReplaceText) {
  return ["pending", "accepted"]?.includes(status) ? (
    <div className={"status-pending"}>{statusReplaceText}</div>
  ) : ["completed"].includes(status) ? (
    <div className={"status-completed"}>{statusReplaceText}</div>
  ) : status == "modify-request" ? (
    <div className={"status-modify-request"}>{statusReplaceText}</div>
  ) : ["cancelled", "rejected"].includes(status) ? (
    <div className={"status-deactive"}>{statusReplaceText}</div>
  ) : ['active'].includes(status) ?
  <div className={"status-active"}>{statusReplaceText}</div>
  : (
    <></>
  );
}
