import React from "react";
import styles from "./Pricing.module.css";
import { AiFillEdit } from "react-icons/ai";
// import { AiFillEdit } from "react-icons/ai";
// import Switch from "../Switch/Switch";
// import EditPackageModal from "../../modals/EditPackeModal";

const PricingCard = ({ item, setSelected, setShowModal }) => {
  return (
    <div
      className={
        item.price !== 0
          ? styles.pricing__card
          : [styles.pricing__card, styles.pricing__card__free].join(" ")
      }
    >
      {/* <button
        onClick={() => {
          setShowModal(true);
          setSelected(item);
        }}
        className={styles.pricing__card__btn}
      >
        <AiFillEdit />
      </button> */}
      <AiFillEdit
        onClick={() => {
          setShowModal(true);
          setSelected(item);
        }}
        className={styles.pricing__card__btn}
        si
      />
      <div className={styles.pricing__card__content}>
        {/* <div> */}
        <div className={styles.headWrap}>
          <p className={`${styles.title} maxLine1`}>
            {item?.packageType} minutes, {(item?.numberOfClasses * 1) / 4}{" "}
            {item?.numberOfClasses == 1 ? "class" : "classes"} per week
          </p>
        </div>

        {/* <p >
            {" "}
            <span className={styles.head}>Package Name: </span>{" "}
            {item?.packageType} minutes, {(item?.numberOfClasses * 1) / 4} class
            per week
          </p> */}
        <div className={styles.content}>
          <p>
            {" "}
            <span className={styles.head}>Package Type: </span>{" "}
            {item.packageType} Minutes
          </p>
          <p>
            {" "}
            <span className={styles.head}>Number Of Classes: </span>{" "}
            {item.numberOfClasses}
          </p>

          {item?.prices?.map((p, index) => (
            <p key={index} className={styles.pricing__card__price}>
              {" "}
              <span className={styles.head}>Price in {p.currency}: </span>
              {p.currency == "usd" ? "$" : "£"}
              {parseFloat(p?.amount).toFixed(2)}
            </p>
          ))}
          <p>
            {" "}
            <span className={styles.head}>Status: </span>{" "}
            {item.isActive ? "Active" : "Deactive"}
          </p>
          <p className={styles.head}> Description: </p>
          {item?.description.slice(0, 2)?.map((p, index) => (
            <p className={styles.description} key={index}>
              {p}
            </p>
          ))}
        </div>
      </div>
      {/* <Switch value={isActive} setter={setIsActive} /> */}
    </div>
    // </div>
  );
};

export default PricingCard;
