import React from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import NoData from "../../Component/NoData/NoData";
import moment from "moment";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import { Button } from "../../Component/Button/Button";
import classes from "./AnnouncementPage.module.css";
import AddAnnouncementModal from "../../modals/AddAnnouncementModal";
import AnnouncementViewModal from "../../modals/AnnouncementViewModal";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import PaginationComponent from "../../Component/PaginationComponent";
import { useState } from "react";

const AnnouncementPage = () => {
  let [show, setShow] = useState("");
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [selectedData, setSelectedData] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);

  const accessToken = useSelector((state) => state.authReducer.access_token);

  const getData = async (page) => {
    setLoading(true);

    const response = await Get(
      BaseURL(`announcement/all?page=${page}&limit=${recordsLimit}`),
      accessToken
    );
    if (response != undefined) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <SideBarSkeleton heading={"Announcements"}>
        <Container className={classes.main}>
          <Row>
            <Col md={12} className={classes?.headerContainer}>
              <div className={classes?.btnContainer}>
                <div className={"text-end mb-3"}>
                  <Button
                    onClick={() => setShow("addModal")}
                    className={[classes.addCourseBtn].join(" ")}
                    label={"Add Announcement"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={classes.tableMain}>
              <div className="table100 ver1 m-b-110">
                <div className="table100-head">
                  <table>
                    <thead>
                      <tr className="row100 head">
                        <th
                          className="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          className="cell100 column2"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Role
                        </th>
                        <th
                          className="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Date
                        </th>
                        <th
                          className="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Title
                        </th>
                        <th
                          className="cell100 column3"
                          style={{ width: "20%", textAlign: "start" }}
                        >
                          Descrioption
                        </th>
                        <th
                          className="cell100 column3"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Created At
                        </th>
                        <th
                          className="cell100 column4"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Status
                        </th>

                        <th
                          className="cell100 column5"
                          style={{ width: "10%" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div className="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr key={index} className="row100 body">
                              <td
                                className="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="cell100 column2"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  {item?.role[0]} {item?.role.length>1 && ",..." }
                                </div>
                              </td>
                              <td
                                className="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                {}
                                {moment(item?.startDate).format(
                                  "DD-MMM"
                                )} / {moment(item?.endDate).format("DD-MMM")}
                              </td>

                              <td
                                className="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {
                                    <ShowMoreShowLessText
                                      text={item?.title}
                                      visibility={50}
                                    />
                                  }
                                </div>
                              </td>
                              <td
                                className="cell100 column4"
                                style={{ width: "20%", textAlign: "left" }}
                              >
                                {
                                  <ShowMoreShowLessText
                                    text={item?.description}
                                    visibility={50}
                                  />
                                }
                              </td>
                              <td
                                className="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.createdAt).format(
                                    "DD MMM YYYY hh:mm"
                                  )}
                                </div>
                              </td>

                              <td
                                className="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {item?.status == "active" ? "Active" : "DeActive"}
                              </td>
                              <td
                                className="cell100 column5"
                                style={{ width: "10%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={"View details"}
                                    onClick={() => {
                                      setSelectedData(item);
                                      setShow(`viewModal`);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Announcement Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>
                { (totalResults > recordsLimit &&
              <Col md={12} className={classes.paginationDiv}>
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
              </Col>
                )}
          </Row>
        </Container>
      </SideBarSkeleton>
      {show == "addModal" && (
        <AddAnnouncementModal
          show={show == "addModal"}
          setShow={setShow}
          setData={setData}
        />
      )}
      {show == "viewModal" && (
        <AnnouncementViewModal
          show={show == "viewModal"}
          setShow={setShow}
          selectedData={selectedData}
        />
      )}
    </>
  );
};

export default AnnouncementPage;
