/* eslint-disable */

import React, { useState } from "react";
import classes from "./MaterialCard.module.css";
import { MdDelete, MdPictureAsPdf } from "react-icons/md";
import { imageUrl } from "../../config/apiUrl";
import RenderMediaModal from "../../modals/RenderMediaModal";

const MaterialCard = ({ data, onClickDelete }) => {
  const { item, type } = data;
  const [showItemModal, setShowItemModal] = useState(false);
  const HandleClick = () => {
    setShowItemModal(true);
  };
  return (
    <>
      <div className={`${classes?.materialCardMain}`} onClick={HandleClick}>
        {type === "pdf" && <MdPictureAsPdf className={classes?.pdfIcon} />}
        {type === "image" && <img src={imageUrl(item)} />}
        {type === "video" && (
          <video controls>
            <source src={item} />
          </video>
        )}
        {onClickDelete && (
          <div className={classes.deleteIcon} onClick={onClickDelete}>
            <MdDelete />
          </div>
        )}
      </div>
      {showItemModal && (
        <RenderMediaModal
          fileKey={item}
          hideToolBar={true}
          type={type}
          show={showItemModal}
          setShow={setShowItemModal}
        />
      )}
    </>
  );
};

export default MaterialCard;
