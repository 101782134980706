import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBrokers: [],
  allCategories: [],
  noti_counter: 0,
  chat_counter: 0,
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setAllCommon(state, action) {
      state.allBrokers = action.payload?.allBrokers;
      state.allCategories = action.payload?.allCategories;
    },

    saveNotiData(state, action) {
      state.noti_counter += action?.payload || 1;
    },
    clearNotiData(state) {
      state.noti_counter = 0;
      state.noti_data = [];
    },
    saveChatCounter(state) {
      state.chat_counter += 1;
    },
    clearChatCounter(state) {
      state.chat_counter = 0;
    },
  },
});

export const {
  setAllCommon,
  saveNotiData,
  saveChatCounter,
  clearChatCounter,
  clearNotiData,
} = commonSlice.actions;

export default commonSlice.reducer;
