import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./ClassDetail.module.css";
import { useParams } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL, imageUrl } from "../../config/apiUrl";
import moment from "moment";
import CourseCard from "../../Component/CourseCard/CourseCard";
import PackageComponent from "../../Component/PackageComponent/PackageComponent";

const ClassDetail = () => {
  const classId = useParams().id;
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [classData, setClassData] = useState(null);

  // getClassDetail
  const getClassDetail = async () => {
    const url = BaseURL(`admin/get-class-detail/${classId} `);
    setIsLoading(true);
    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setClassData(response?.data?.data);
    }
  };

  useEffect(() => {
    getClassDetail();
  }, []);

  return (
    <div>
      <SideBarSkeleton>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row
              style={{
                alignItems: "end",
              }}
            >
              <Col md={12}>
                <div className={classes.top_head_div}>
                  <h3>
                    <b>Class Details</b>
                  </h3>
                </div>
              </Col>
              <Col md={3}>
                <h5 className={classes?.mainHeading}>Student Detail</h5>
                <div className={classes.user_left_card}>
                  <div className={classes.img_wrapper}>
                    <img
                      src={imageUrl(classData?.student?.photo)}
                      alt="student"
                    />
                  </div>

                  <div className={classes.user_info}>
                    <h5 className="t-t-c">
                      {classData?.student?.firstName}{" "}
                      {classData?.student?.lastName}
                    </h5>
                    <p>
                      <b>Age:</b> {classData?.student?.age}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className={classes.user_left_card}>
                  <Row>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Email</b>
                        <p>{classData?.student?.email}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Phone:</b>
                        <p>{classData?.student?.phone}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Alt Phone:</b>
                        <p>{classData?.student?.altPhone}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Nationality:</b>
                        <p>{classData?.student?.nationality}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Gender:</b>
                        <p>{classData?.student?.gender}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Address:</b>
                        <p>{classData?.student?.address || "N/A"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className={classes.second_detail_row}>
              <Col md={3}>
                <h5 className={classes?.mainHeading}>Teacher Details</h5>
                <div className={classes.user_left_card}>
                  <div className={classes.img_wrapper}>
                    <img
                      src={imageUrl(classData?.teacher?.photo)}
                      alt="teacher"
                    />
                  </div>

                  <div className={classes.user_info}>
                    <h5 className="t-t-c">
                      {classData?.teacher?.firstName}{" "}
                      {classData?.teacher?.lastName}
                    </h5>
                    <p>
                      <b>Age:</b> {classData?.teacher?.age}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className={classes.user_left_card}>
                  <Row>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Email:</b>
                        <p>{classData?.teacher?.email}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Phone:</b>
                        <p>{classData?.teacher?.phone}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Alt Phone:</b>
                        <p>{classData?.teacher?.altPhone}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Nationality:</b>
                        <p>{classData?.teacher?.nationality}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Gender:</b>
                        <p>{classData?.teacher?.gender}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Address:</b>
                        <p>{classData?.teacher?.address || "N/A"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className={classes.second_detail_row}>
              <h5 className={classes?.mainHeading}>Class Details</h5>
              <div className={classes.classDetailContainer}>
                <Row>
                  <Col md={6}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Class Date:</b>
                      <p>{moment(classData?.date).format("Do, dd MMM YYY")}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Class Time:</b>
                      <p>
                        {moment(classData?.startTime, "HH:mm").format(
                          "hh:mm A"
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Class Type:</b>
                      <p className="t-t-c">{classData?.classType}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Status:</b>
                      <p className="t-t-c">{classData?.status}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className={classes.second_detail_row}>
              <h5 className={classes?.mainHeading}>Course Detail</h5>
              <Col md={4}>
                <CourseCard
                  data={classData?.subscription?.course}
                  onClick={() => {}}
                  showEditBtn={false}
                />
              </Col>
            </Row>

            <Row className={classes.second_detail_row}>
              <h5 className={classes?.mainHeading}>Package Detail</h5>
              <Col md={4}>
                <PackageComponent
                  data={classData?.subscription?.package}
                  btnVisibility={false}
                  onClick={() => {}}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </div>
  );
};

export default ClassDetail;
