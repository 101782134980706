import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-modern-drawer/dist/index.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { Suspense, lazy, useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRouter from "./Helper/ProtectedRoute";
import { Loader } from "./Component/Loader";
import MyProfile from "./pages/MyProfile";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { apiUrl, BaseURL } from "./config/apiUrl";
import { Beforeunload } from "react-beforeunload";
import Notifications from "./pages/Notifications";
import { saveChatCounter, saveNotiData } from "./store/common/commonSlice";
import { Get } from "./Axios/AxiosFunctions";
import { updateUser } from "./store/auth/authSlice";

import Packages from "./pages/Packages";
import CreateTeacher from "./pages/CreateTeacher";
import ListOfAllFamilies from "./pages/ListOfAllFamilies/ListOfAllFamilies";
import ListOfChildrenOfParent from "./pages/ListOfChildrenOfParent/ListOfChildrenOfParent";
import ListOfAllTeachers from "./pages/ListOfAllTeachers/ListOfAllTeachers";
import AddOrEditTeacherPage from "./pages/AddOrEditTeacherPage/AddOrEditTeacherPage";
import AllCourses from "./pages/AllCourses";
import AllLessons from "./pages/AllLessons";
import AnnouncementPage from "./pages/AnnouncementPage";
import ListOfAllSubAdmins from "./pages/ListOfAllSubAdmins/ListOfAllSubAdmins";
import AddOrEditSubAdminPage from "./pages/AddOrEditSubAdminPage/AddOrEditSubAdminPage";
import AllClassRequest from "./pages/AllClassRequest";
import ClassRequestDetail from "./pages/ClassRequestDetail";
import LessonLearningMaterial from "./pages/LessonLearningMaterial";
import ListOfTodaysClasses from "./pages/ListOfTodaysClasses/ListOfTodaysClasses";
import ClassDetail from "./pages/ClassDetail";
import AllClasses from "./pages/AllClasses";
import Salary from "./pages/Salary";
import SubscriptionDetailPage from "./pages/SubscriptionDetailPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import ComplaintRecords from "./pages/ComplaintRecords";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Transactions = lazy(() => import("./pages/Transactions"));
const AllUsers = lazy(() => import("./pages/AllUsers"));
const NotFound = lazy(() => import("./pages/NotFound"));
const TaskPage = lazy(() => import("./pages/TaskPage"));
const TaskDetailPage = lazy(() => import("./pages/TaskDetailPage"));
const UserDetailScreen = lazy(() =>
  import("./pages/UserDetailScreen/UserDetailScreen")
);
const HolidayPage = lazy(() => import("./pages/HolidayPage/HolidayPage"));

export let socket = null;
function App() {
  const {
    user,
    isLogin,
    access_token: accessToken,
  } = useSelector((state) => state?.authReducer);
  const userDataState = useSelector((state) => state?.authReducer?.user);
  const dispatch = useDispatch();

  const fetchNotificationsForCount = async () => {
    const url = BaseURL("users/notifications?page=1&limit=5&noCountUpdate=true");
    let response = await Get(url, accessToken);

    if (response !== undefined) {
      response?.data?.newNotifications > 0 &&
        dispatch(saveNotiData(response?.data?.newNotifications));
    }
  };

  const getMe = async () => {
    const url = BaseURL("users/me");
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      const apiUser = response?.data?.data?.user;
      dispatch(updateUser(apiUser));
    }
  };

  useEffect(() => {
    if (isLogin) {
      socket = io(apiUrl, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 180000,
        reconnectionDelayMax: 300000,
      });
      socket?.emit("join", userDataState?._id);
      fetchNotificationsForCount();
      getMe();
    }
  }, [isLogin]);

  useEffect(() => {
    socket?.on("new-notification", (notification) => {
      //something went wrong here
      if (
        notification?.receiver == userDataState?._id &&
        window.location.pathname !== "/notifications" &&
        notification?.flag == "project"
      ) {
        dispatch(saveNotiData());
      }
      //something went wrong here

      if (
        notification?.receiver == userDataState?._id &&
        notification?.flag == "chat" &&
        window.location.pathname !== "/chat"
      ) {
        dispatch(saveChatCounter(notification));
        dispatch(saveNotiData());
      }
    });
    return () => {
      socket?.off("new-notification");
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Beforeunload
        onBeforeunload={() => {
          if (isLogin) {
            socket?.emit("disconnected", user?._id);
          }
        }}
      />
      <BrowserRouter>
        <Suspense fallback={<Loader className={"mvh-100"} />}>
          <Routes>
            {!isLogin && (
              <Route
                path="/"
                exact
                // element={<BeforeLoginRoute
                element={<Login />}
                // />}
              />
            )}
            {/* Course Route Start */}
            <Route
              path="/course/all"
              exact
              element={<ProtectedRouter element={<AllCourses />} />}
            />
            <Route
              path="/course/:courseSlug"
              exact
              element={<ProtectedRouter element={<AllLessons />} />}
            />
            <Route
              path="/course/:courseSlug/:lessonSlug"
              exact
              element={<ProtectedRouter element={<LessonLearningMaterial />} />}
            />
            {/* Course Route End */}

            <Route
              path="/dashboard"
              exact
              element={<ProtectedRouter element={<Dashboard />} />}
            />
            <Route
              path="/family/all"
              exact
              element={<ProtectedRouter element={<ListOfAllFamilies />} />}
            />
            <Route
              path="/family/:slug"
              exact
              element={<ProtectedRouter element={<ListOfChildrenOfParent />} />}
            />
            <Route
              path="/user/:slug"
              exact
              element={<ProtectedRouter element={<UserDetailScreen />} />}
            />
            <Route
              path="/teacher/all"
              exact
              element={<ProtectedRouter element={<ListOfAllTeachers />} />}
            />
            <Route
              path="/teacher/new"
              exact
              element={<ProtectedRouter element={<AddOrEditTeacherPage />} />}
            />
            <Route
              path="/teacher/edit"
              exact
              element={<ProtectedRouter element={<AddOrEditTeacherPage />} />}
            />

            <Route
              path="/sub-admin/all"
              exact
              element={<ProtectedRouter element={<ListOfAllSubAdmins />} />}
            />
            <Route
              path="/sub-admin/new"
              exact
              element={<ProtectedRouter element={<AddOrEditSubAdminPage />} />}
            />
            <Route
              path="/sub-admin/edit"
              exact
              element={<ProtectedRouter element={<AddOrEditSubAdminPage />} />}
            />

            <Route
              path="/class/request"
              exact
              element={<ProtectedRouter element={<AllClassRequest />} />}
            />
            <Route
              path="/class/request/:id"
              exact
              element={<ProtectedRouter element={<ClassRequestDetail />} />}
            />
            <Route
              path="/class/today"
              exact
              element={<ProtectedRouter element={<ListOfTodaysClasses />} />}
            />
            <Route
              path="/class/all"
              exact
              element={<ProtectedRouter element={<AllClasses />} />}
            />
            <Route
              path="/class/:id"
              exact
              element={<ProtectedRouter element={<ClassDetail />} />}
            />

            {/* Delete this page */}
            <Route path="/createTeacher" exact element={<CreateTeacher />} />

            <Route
              path="/transactions"
              exact
              element={<ProtectedRouter element={<Transactions />} />}
            />
            <Route
              path="/all-users"
              exact
              element={<ProtectedRouter element={<AllUsers />} />}
            />

            <Route
              path="/my-profile"
              exact
              element={<ProtectedRouter element={<MyProfile />} />}
            />
            <Route
              path="/packages"
              exact
              element={<ProtectedRouter element={<Packages />} />}
            />
            <Route
              path="/salaries"
              exact
              element={<ProtectedRouter element={<Salary />} />}
            />

            <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<Notifications />} />}
            />
            <Route
              path="/announcement/all"
              exact
              element={<ProtectedRouter element={<AnnouncementPage />} />}
            />
            <Route
              path="/task/all"
              exact
              element={<ProtectedRouter element={<TaskPage />} />}
            />
            <Route
              path="/task/detail/:id"
              exact
              element={<ProtectedRouter element={<TaskDetailPage />} />}
            />
            <Route
              path="/subscriptions/all"
              exact
              element={<ProtectedRouter element={<SubscriptionPage />} />}
            />
            <Route
              path="/subscriptions/:id"
              exact
              element={<ProtectedRouter element={<SubscriptionDetailPage />} />}
            />
            <Route
              path="/complain/all"
              exact
              element={<ProtectedRouter element={<ComplaintRecords />} />}
            />
            <Route
              path="/holidays"
              exact
              element={<ProtectedRouter element={<HolidayPage />} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
