import React from "react";

import classes from "./CourseCard.module.css";
import { imageUrl } from "../../config/apiUrl";
import { RiEdit2Fill } from "react-icons/ri";

const CourseCard = ({
  data,
  selectedCourse,
  onClick,
  showEditBtn = true,
  onClickEdit,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${classes?.courseCardMain} ${
        selectedCourse?._id === data?._id && classes?.selectedCourseMain
      }`}
    >
      <div className={classes?.courseImageContainer}>
        <img
          src={imageUrl(data?.coverImage)}
          alt="coverImage"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = dummyCourseImage;
          }}
        />
      </div>
      <div className={classes?.courseDetails}>
        <h3 className="t-t-c">{data?.title}</h3>
        <p>{data?.description}</p>
        <div className={classes?.courseDetailsBottom}>
          <div className={classes?.courseDetailsBottomLeft}>
            <p>
              <span>Category:</span> {data?.category}
            </p>
          </div>
          <div className={classes?.courseDetailsBottomRight}>
            <p>
              <span>Minimum Age:</span> {data?.minimumAge}
            </p>
          </div>
        </div>
      </div>
      {showEditBtn && (
        <div className={classes.editIcon} onClick={onClickEdit}>
          <RiEdit2Fill />
        </div>
      )}
    </div>
  );
};

export default CourseCard;

let dummyCourseImage =
  "https://media.wired.com/photos/64021deee9beb77b09e83b93/3:2/w_1280%2Cc_limit/Quanta_IntransitiveDice-byKristinaArmitage-Lede-scaled-copy.jpg";
