/* eslint-disable */

import React, { useState } from "react";
import classes from "./ComplaintDetailModal.module.css";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import { toast } from "react-toastify";
import {
  BaseURL,
  apiHeader,
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer,
} from "../../config/apiUrl";

import { Button } from "../../Component/Button/Button";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import { Patch } from "../../Axios/AxiosFunctions";
import { TextArea } from "../../Component/TextArea";
import { useSelector } from "react-redux";

const ComplaintDetailModal = ({
  show,
  setShow,
  data,
  setData,
  selectedData,
}) => {
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const [reason, setReason] = useState("");
  const [isReject, setIsReject] = useState(false);
  const [isLoading, setIsLoading] = useState("");

  const handleSubmit = async (sts) => {
    const params = {
      status: sts,
      ...(isReject && { reason: reason }),
    };
    const url = `complain/action/${selectedData?._id}`;
    setIsLoading(sts);
    const response = await Patch(BaseURL(url), params, apiHeader(accessToken));
    if (response) {
      const temp=[...data]
      temp.splice(selectedData?.index,1,response?.data?.data)
      setData(temp)
      toast.success(`Complain Successfully ${capitalizeFirstLetter(sts)}`);
    }
    setIsLoading("");
    setShow(false)
  };
  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="1000px"
        borderRadius="20px"
        header={`Complain Details`}
        showCloseIcon
      >
        <div className={classes.addProjectModal_main}>
          <Row className={classes.addProject_row}>
            {selectedData?.student?.firstName && (
              <Col md={6}>
                <p className="t-t-c">
                  <b>Student Name: </b> {selectedData?.student?.firstName}{" "}
                  {selectedData?.student?.lastName}
                </p>
              </Col>
            )}
            {selectedData?.teacher?.firstName && (
              <Col md={6}>
                <p className="t-t-c">
                  <b> Teacher Name: </b> {selectedData?.teacher?.firstName}{" "}
                  {selectedData?.teacher?.lastName}
                </p>
              </Col>
            )}
            <Col md={6}>
              <p className="t-t-c">
                <b> Complain Type: </b> {selectedData?.complainType}{" "}
              </p>
            </Col>
            {selectedData?.course?.title && (
              <Col md={6}>
                <p className="t-t-c">
                  <b>Course: </b> {selectedData?.course?.title}
                </p>
              </Col>
            )}
            <Col md={6}>
              <p className="t-t-c">
                <b>Status: </b> {selectedData?.status}{" "}
              </p>
            </Col>
            <Col md={6}>
              <p className="t-t-c">
                <b>Class Type: </b>{" "}
                {selectedData?.student?.subscriptions?.at(-1).status ==
                  "notPaid" && selectedData?.student?.subscriptions?.length == 1
                  ? "Trial"
                  : "Regular"}{" "}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b> Subject: </b>{" "}
                {
                  <ShowMoreShowLessText
                    text={selectedData?.subject}
                    visibility={100}
                  />
                }{" "}
              </p>
            </Col>
            <Col md={12}>
              <p>
                <b> Complain: </b>{" "}
                {
                  <ShowMoreShowLessText
                    text={selectedData?.complain}
                    visibility={100}
                  />
                }{" "}
              </p>
            </Col>
            {selectedData?.status=="rejected"&&<Col md={12}>
              <p>
                <b> Reject Reason: </b>{" "}
                {
                  <ShowMoreShowLessText
                    text={selectedData?.reason}
                    visibility={100}
                  />
                }{" "}
              </p>
            </Col>}
            {isReject && (
              <Col md={12}>
                <TextArea
                  label={"Reason"}
                  value={reason}
                  setter={setReason}
                  placeholder={"Enter Reason"}
                />
              </Col>
            )}
          </Row>
          {selectedData?.status ==="in-progress" && <div className={classes.btn_main}>
            {!isReject && (
              <Button
                className={classes.btn}
                onClick={() => {
                  handleSubmit("resolved");
                }}
                label={isLoading=="resolved" ? "Wait..." : "Resolve"}
              />
            )}
            <Button
              onClick={() => {
                setIsReject(true);
                reason && handleSubmit("rejected");
              }}
              className={classes.btn}
              label={isLoading=="rejected" ? "Wait..." : "Reject"}
            />
          </div>}
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default ComplaintDetailModal;
