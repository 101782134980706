import React, { useState } from "react";
import classes from "./AddOrEditLessonModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import ModalSkeleton from "../ModalSkeleton";
import { toast } from "react-toastify";
import { TextArea } from "../../Component/TextArea";
import { formRegEx, formRegExReplacer } from "../../config/apiUrl";

const AddOrEditLessonModal = ({
  show,
  setShow,
  handleSubmit,
  isLoading = false,
  data,
}) => {
  const [title, setTitle] = useState(data?.title || "");
  const [description, setDescription] = useState(data?.description || "");

  const HandleSubmitData = async () => {
    let params = {
      title,
      description,
    };

    for (let key in params) {
      if (
        params[key] == "" ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please fill the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()} field!`
        );
      }
    }
    await handleSubmit(params);
  };
  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="700px"
        borderRadius="20px"
        header={`${data ? "Edit" : "Add"} Lesson`}
      >
        <div className={classes.addProjectModal_main}>
          <Row className={classes.addProject_row}>
            <Col md={12}>
              <Input
                setter={setTitle}
                value={title}
                placeholder={"enter title..."}
                label={"Title"}
              />
            </Col>
            <Col md={12}>
              <TextArea
                placeholder={"Enter Description..."}
                value={description}
                setter={setDescription}
                label={"Description"}
              />
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={HandleSubmitData}
              className={classes.btn}
              label={isLoading ? "Submitting..." : "Submit"}
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddOrEditLessonModal;
